// ** React Imports
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// ** Custom Components
// import Avatar from '../../@core/components/avatar'

// ** React Imports
import { Fragment } from 'react'
import React from "react";
// ** Custom Components
import Avatar from '../../@core/components/avatar'
import "./NotificationDropdown.css";
import { browserHistory } from 'react-router';
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Bell, X, Check, AlertTriangle } from 'react-feather'

import Api from '../../services/api-service.js'
import Auth from '../../services/auth-service.js'

import {
  Badge,
  Media,
  CustomInput,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'


// // ** Utils
// // import { isUserLoggedIn } from '@utils'

// // ** Store & Actions
// import { useDispatch } from 'react-redux'
// // import { handleLogout } from '@store/actions/auth'

// // ** Third Party Components
// import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { Folder, HelpCircle, Power } from 'react-feather'

// // ** Default Avataraaaaa
import defaultAvatar from '../../assets/img/person.png'

const manualURL = "https://aiot.constecoin.com/repositorio/manual.pdf"
const organizacion = Auth.getOrganizaciones();

class UserDropdown extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      notificationsArray: [],
      //  userAvatar = (userData && userData.avatar) || defaultAvatar
      userAvatar: defaultAvatar,
      modalOpen: false,
      organizaciones: Auth.getOrganizaciones()
    };

  }
  // ** Store Vars
  //   const dispatch = useDispatch()

  // ** State
  //   const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  //   useEffect(() => {
  // if (isUserLoggedIn() !== null) {
  //   setUserData(JSON.parse(localStorage.getItem('userData')))
  // }
  //   }, [])

  handleLogout() {
    Auth.saveJWT(null)
    Auth.saveHabilitations(null)
  }

  handleItemClick = () => {
    // Llamamos a la función onClick pasada por el componente padre (Top)
    if (this.props.onClick) {
      this.props.onClick(); // Abre el modal
    }
  };

  renderNotificationItems() {
    return (
      <PerfectScrollbar
        component='li'
        className='media-list scrollable-container'
        options={{
          wheelPropagation: false
        }}
      >
        {this.state.notificationsArray.map((item, index) => {
          return (
            <a key={index} className='d-flex' onClick={(e) => { e.preventDefault() }}>
              {/* <Redirect to={'/admin/home'} /> */}
              {/* <a key={index} className='d-flex' href='/' onClick={e => e.preventDefault()}>onClick={() => { history.push('/new-location') }} */}
              {/* render() { return <Redirect to={'/admin/home'} />} */}
              <Media
                className={classnames('d-flex', {
                  'align-items-start': !item.switch,
                  'align-items-center': item.switch
                })}
              >
                {!item.switch ? (
                  <Fragment>
                    {/* <Media left>
                      <Avatar
                        {...(item.img
                          ? { img: item.img, imgHeight: 32, imgWidth: 32 }
                          : item.avatarContent
                          ? {
                              content: item.avatarContent,
                              color: item.color
                            }
                          : item.avatarIcon
                          ? {
                              icon: item.avatarIcon,
                              color: item.color
                            }
                          : null)}
                      />
                    </Media> */}
                    <Media body className="media-body">
                      <p className="titulo-notificaciones" onClick={ev => {
                        // redirect();
                      }}>
                        {item.nombre}

                      </p>
                      <small className='subtitulo-notificaciones'>{item.organizacion}</small>
                    </Media>
                  </Fragment>
                ) : (
                  <Fragment>
                    {item.title}
                    {item.switch}
                  </Fragment>
                )}
              </Media>
            </a>
          )
        })}
      </PerfectScrollbar>
    )
  }
  /*eslint-enable */
  render() {
    const { organizaciones } = this.state;

    // Verificar si alguna de las organizaciones tiene 'sto_epconst'
    const tieneStoEpconst = organizaciones.some(org => org.organizacion === 'sto_epconst');
    return (
      //   <></>
      <UncontrolledDropdown tag='li' className='notification-dropdown dropdown-notification nav-item mr-25 '>
        <DropdownToggle tag='a' className='nav-link' href='/' onClick={e => e.preventDefault()}>
          <div style={{ maxWidth: '40px' }}>
            <Avatar img={this.state.userAvatar} imgHeight='40' imgWidth='40' status='online' />
          </div>

          {/* <Bell size={21} /> */}
          {/* <Badge pill color='danger' className='badge-up'> */}
          {/* {this.state.notificationsArray.length} */}
          {/* </Badge> */}
        </DropdownToggle>
        <DropdownMenu tag='ul' right className='dropdown-menu-media mt-0'>

          {/* <DropdownItem tag={Link} to='/apps/chat'>
          <MessageSquare size={14} className='mr-75' />
          <span className='align-middle'>Chats</span>
        </DropdownItem> */}

          {/* <DropdownItem divider /> */}
          {/* <DropdownItem tag={Link} to='/admin/cuentaUsuario'>
            <Settings size={14} className='mr-75' />
            <span className='align-middle configuracion-perfil'>Perfil</span>
          </DropdownItem> */}

          <DropdownItem href="http://www.soporte.constecoin.com/otrs/customer.pl?_ga=2.157880178.990873719.1639492103-1433824770.1639492103&_gl=1*u41md5*_ga*MTQzMzgyNDc3MC4xNjM5NDkyMTAz*_ga_P7141D6QES*MTYzOTQ5MjEwMS4xLjAuMTYzOTQ5MjEwMS4w" target="_blank">
            <HelpCircle size={14} className='mr-75' />
            <span className='align-middle configuracion-perfil'>Soporte</span>
          </DropdownItem>

          {/* Renderiza el elemento solo si alguna organización es "sto_epconst" */}
          {tieneStoEpconst && (
            <DropdownItem onClick={this.handleItemClick}>
              <Folder size={14} className='mr-75' />
              <span className='align-middle configuracion-perfil'>Manuales</span>
            </DropdownItem>
          )}

          <DropdownItem tag={Link} to='/login' onClick={() => this.handleLogout()}>
            <Power size={14} className='mr-75' />
            <span className='align-middle configuracion-perfil'>Logout</span>
          </DropdownItem>


        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }
}


// render(){
//   return (
//     <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
//       <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
//         {/* <div className='user-nav d-sm-flex d-none'>
//           <span className='user-name font-weight-bold'>{(userData && userData['username']) || 'John Doe'}</span>
//           <span className='user-status'>{(userData && userData.role) || 'Admin'}</span>
//         </div> */}
// {/* <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' /> */}
//       </DropdownToggle>
//       <DropdownMenu right>
//         {/* <DropdownItem tag={Link} to='/pages/profile'>
//           <User size={14} className='mr-75' />
//           <span className='align-middle'>Profile</span>
//         </DropdownItem>
//         <DropdownItem tag={Link} to='/apps/email'>
//           <Mail size={14} className='mr-75' />
//           <span className='align-middle'>Inbox</span>
//         </DropdownItem>
//         <DropdownItem tag={Link} to='/apps/todo'>
//           <CheckSquare size={14} className='mr-75' />
//           <span className='align-middle'>Tasks</span>
//         </DropdownItem>
// <DropdownItem tag={Link} to='/apps/chat'>
//   <MessageSquare size={14} className='mr-75' />
//   <span className='align-middle'>Chats</span>
// </DropdownItem>
//         <DropdownItem divider />
//         <DropdownItem tag={Link} to='/pages/account-settings'>
//           <Settings size={14} className='mr-75' />
//           <span className='align-middle'>Settings</span>
//         </DropdownItem>
//         <DropdownItem tag={Link} to='/pages/pricing'>
//           <CreditCard size={14} className='mr-75' />
//           <span className='align-middle'>Pricing</span>
//         </DropdownItem>
//         <DropdownItem tag={Link} to='/pages/faq'>
//           <HelpCircle size={14} className='mr-75' />
//           <span className='align-middle'>FAQ</span>
//         </DropdownItem>
//         <DropdownItem tag={Link} to='/login' onClick={() => dispatch(handleLogout())}>
//           <Power size={14} className='mr-75' />
//           <span className='align-middle'>Logout</span>
//         </DropdownItem> */}
//       </DropdownMenu>
//     </UncontrolledDropdown>
//   )
// }
// }

export default UserDropdown
