import React from 'react'
import "./styles.css";
import CloseIcon from '@material-ui/icons/Close';

const Index = ({ isOpen, onClose, children }) => {
    const handleBackgroundClick = (event) => {
        if (event.target.classList.contains("modal-backdrop")) {
            onClose();
        }
    };

    return isOpen ? (
        <div className="modal-container">
            <div className="modal-backdrop" onClick={handleBackgroundClick}>
                <div className="modall" onClick={(event) => event.stopPropagation()}>
                    <div className="modal-body">{children}</div>
                </div>
            </div>
        </div>
    ) : null;
}

export default Index