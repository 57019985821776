import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./styles.css"

function InputWithInfo(props) {
    const [showInfo, setShowInfo] = useState(false);

    const handleMouseEnter = () => {
        setShowInfo(true);
    };

    const handleMouseLeave = () => {
        setShowInfo(false);
    };

    return (
        <>
            <div className="info-icon" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <FontAwesomeIcon icon={faInfoCircle} />
                {showInfo && <div className="info-text">{props.info}</div>}
            </div>
        </>

    );
}

export default InputWithInfo;