import React, { useState, useEffect, useMemo } from "react";
import ModalR from "../../components/Modal/Modal right-left/index";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ReplyIcon from '@material-ui/icons/Reply';
import ModalCentro from "../../components/Modal/Modal Centro";
import AUTH from "../../services/auth-service";
import API from "../../services/api-service";
import { useLocation } from "react-router-dom";
import Select1 from "../../components/Selects/Select1";
import toast, { Toaster} from "react-hot-toast";
import Sidebar from "../../components/Dashboard/Components/Sidebar";
import { Back, BackD, CTituloModal, ContenedorSe, Contenido, TablaDispo, Th, Tr, TrC, ContenedorDis, Nombredispositivo, DescripcionDispo, Organizacion} from "./DashboardElements";
import { TituloModal } from "./DashboardElements";
import TemperaturaD from "../../components/CamposWidgets/Termometro/index"
import GaugeD from "../../components/CamposWidgets/Gauge/index"
import Indicador from "../../components/CamposWidgets/Indicador"
import Line from "../../components/CamposWidgets/Line"
import Texto from "../../components/CamposWidgets/Texto"
import Display from "../../components/CamposWidgets/Display"
import Button from "@mui/material/Button";
import LineTest from "../../components/CamposWidgets/TestLine/index"
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const Probando = (props) => {
  const query = new URLSearchParams(useLocation().search);
  const organizacionn = query.get("organizacion");
  const localizacionn = query.get("localizacion");
  const [showModal, setShowModal] = useState(false);
  const [showModall, setShowModall] = useState(false);
  const [Dispositivos, setDispositivos] = useState([]);  
  const [DispositivoE, setDispositivoE] = useState("");
  const [datoTabla, setDatoTabla] = useState([]);

  // Aqui comienza testing
  
  // aqui acaba testing

  const [data, setData] = useState([]);

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [showVariables, setShowVariables] = useState(false);

  //Para despliqgar la variable en donde tiene que ser
  const [variableElegida, setVariableElegida] = useState({});

  const [valorWid, setValorWid] = useState("");
  const [variable, setVariable] = useState("")
  const [device, setDevice] = useState("") 

  const [test, setTest] = useState("")
  
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModall = () => {    
    setShowVariables(false)   

    let organizaciones = AUTH.getOrganizaciones();
    organizaciones.map((index, i) => {
      if (index.organizacion == organizacionn) {
        index.localizacion.map((localizacion, j) => {
          if (localizacion.localiza == localizacionn) {
            let documento = [];
            localizacion.configuraciones.map((confi, k) => {
              documento.push({
                label: confi.nombre,
                value: confi.catalogo
              });
            });
            setDispositivos(documento);        
          }
        });
      }
    });    
    setShowModall(true);
  };

  const handleCloseModall = () => {
    setShowModall(false);
  };

  const saveData = (data) => {

    handleCloseModal();
    // props.cerrarModal();
    props.funcionO(data);
    
    // toast.promise(
    //   API.agregarNuevoWidget(data).then((response) => {
    //     handleCloseModal();
    //     props.cerrarModal();
    //     props.funcionO(data);
    //   }),
    //   {
    //     loading: "Cargando...",
    //     success: <b>Widget Creado.!</b>,
    //     error: (err) => <b>Error al cargar datos!</b>,
    //   }
    // );
  }


  const ConsumirDispositivo = (valor) => {
    setDispositivoE(valor);
    let Data = {
      organizacion: organizacionn,
      localizacion: localizacionn,
      tipoDispositivo: valor,
    };
    toast.promise(
      API.obtenerDispositivosD(Data).then((respuesta) => {
        setDatoTabla(respuesta);
      }),
      {
        loading: "Cargando...",
        success: <b>Datos cargados!</b>,
        error: (err) => <b>Error al cargar datos!</b>,
      }
    );
  };

  function consumir() {
    setData([]);
    let data = {
      organizacion: organizacionn,
      localizacion: localizacionn,
    };
    API.getAllDBD(data).then((response) => {
      if (Object.keys(response).length > 0) {
        setData(response);
      }
    });
  }

  useEffect(() => {
    consumir();
  }, [datoTabla]);
  // aqui debe llegar valores como
  // se debe reenviar nombre del widgetr en la funcion de retorno

  const ProbandoIcono = (valor) => {
    if (valor != null) {
      setValorWid(valor);      
      handleShowModal();      
    }
  };

  const probandoTest = (test) => {
    setTest(test)
  }

  function VerificarId(valor) {
    setSelectedDevice(valor);
    setShowVariables(!showVariables);
  }

  const handleVariableClick = (variable) => {  
    handleCloseModall();
    const partes = variable.split("-");
    const antes = partes[0];
    const despues = partes[1];
    const nombre = partes[2];
    if (nombre == "" || nombre == undefined || nombre == null) {
      nombre = despues
    }
    setVariable(antes)
    setDevice(despues)
    let resultado = {
      deviceID: despues,
      variable: antes,
      nombre: nombre
    };
    setVariableElegida(resultado);
  };

  return (
    <>
      <ModalCentro isOpen={showModall} onClose={handleCloseModall}>

        <h4>Seleccione una variable</h4>
        <Button style={{ position: "absolute", top: "10px", right: "10px" }} variant="contained" color="primary" onClick={handleCloseModall}>
          X
        </Button>
        <Select1
          message="Dispositivos"
          value={DispositivoE}
          setFunction={ConsumirDispositivo}
          data={Dispositivos}
          id="outlined-disabled"
        />

        <TablaDispo>
          <Tr>
            <Th>Dispositivo</Th>
            <Th>Ultimo dato</Th>
          </Tr>



          {datoTabla.length > 0 ? (
            datoTabla.map((dato, index) => (
              <>
                {dato.ultimoReporte != "Nunca a Reportado" ? <TrC>
                  <td>
                    <ContenedorDis>
                      <Nombredispositivo key={index} onClick={() => VerificarId(dato.deviceID)}> {dato.name}</Nombredispositivo>
                      <DescripcionDispo>{dato.deviceID}</DescripcionDispo>
                    </ContenedorDis></td>
                  <td>
                    <Organizacion>{dato.ultimoReporte}</Organizacion>
                  </td>
                </TrC> : null}
                {showVariables &&
                  selectedDevice &&
                  selectedDevice === dato.deviceID && (
                    <>
                      <div>
                        {dato.variables.map((variable, index) => (
                          <p
                            key={index}
                            onClick={() =>
                              handleVariableClick(variable + "-" + dato.deviceID + "-" + dato.name)
                            }
                          ><ArrowRightIcon />
                            {variable}
                          </p>
                        ))}
                      </div>
                    </>
                  )}

              </>
            ))
          ) : (
            <p>No hay datos</p>
          )}
        </TablaDispo>
      </ModalCentro>
      {/* Selecionar el dispositivo para los valores del widgetz */}
      <ModalR isOpen={showModal} onClose={handleCloseModal}>
        <CTituloModal>
          <TituloModal>{valorWid}</TituloModal>
        </CTituloModal>

        <ContenedorSe>
          <Back onClick={handleCloseModal}><ReplyIcon /><BackD>Regresar</BackD></Back>

          <Contenido>


            {valorWid == "Temperatura" ? <>
              <TemperaturaD
                data={data}
                valorWid={valorWid}
                device={device}
                variable={variable}
                variableElegida={variableElegida}
                showModalVariable={handleShowModall}
                DispositivoE={DispositivoE}
                saveData={saveData}
              />
            </> : null}

            {valorWid == "Gauge" ? <>
              <GaugeD
                data={data}
                valorWid={valorWid}
                device={device}
                variable={variable}
                variableElegida={variableElegida}
                showModalVariable={handleShowModall}
                DispositivoE={DispositivoE}
                saveData={saveData}
              />
            </> : null}

            {valorWid == "Humedad" || valorWid == "Batería" ? <><Indicador
              data={data}
              valorWid={valorWid}
              device={device}
              variable={variable}
              variableElegida={variableElegida}
              showModalVariable={handleShowModall}
              DispositivoE={DispositivoE}
              saveData={saveData}
            /></> : null}

            {(valorWid == "LineChart" || valorWid == "Metrica") && test=="" ? <><Line
              data={data}
              valorWid={valorWid}
              device={device}
              variable={variable}
              variableElegida={variableElegida}
              showModalVariable={handleShowModall}
              DispositivoE={DispositivoE}
              saveData={saveData}
             
              setVariable = {setVariable}
              setDevice = {setDevice}
              setVariableElegida = {setVariableElegida}
              ConsumirDispositivo = {ConsumirDispositivo}
              Dispositivos = {Dispositivos}
              datoTabla = {datoTabla}
              VerificarId = {VerificarId}
              showVariables = {showVariables}
              selectedDevice = {selectedDevice}
              organizacionn = {organizacionn}
              localizacionn = {localizacionn}

            /></> : null}

            {valorWid == "Texto" ? <><Texto
              data={data}
              saveData={saveData}
            /></> : null}

            {valorWid == "Entrada" ? <><Display
              data={data}
              valorWid={valorWid}
              device={device}
              variable={variable}
              variableElegida={variableElegida}
              showModalVariable={handleShowModall}
              DispositivoE={DispositivoE}
              saveData={saveData}
            /></> : null}

            {valorWid == "LineChart" && test == "test" ? <><LineTest
              data={data}
              valorWid={valorWid}
              device={device}
              variable={variable}
              variableElegida={variableElegida}
              showModalVariable={handleShowModall}
              DispositivoE={DispositivoE}
              saveData={saveData}
            /></>:null}

          </Contenido>
        </ContenedorSe>
      </ModalR>      
      <Sidebar setWidget={ProbandoIcono} setTest={probandoTest}/>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};


export default Probando;
