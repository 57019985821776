import React, { forwardRef } from "react";
import { Actalizacion, Contenedor, Main, NombreD, IconoEdit, StyledReactGridItem, Testing, Titulo } from './ElementsDisplay';
import EditIcon from '@material-ui/icons/Edit';
import GaugeChart from 'react-gauge-chart';
import GaugeComponent from 'react-gauge-component';

import './gauge.css'

class gauge extends React.Component {
	constructor(props) {
		super(props);
		this.eliminarWidget = this.props.funcionE.bind(this);
		this.state = {
			Mnombre: this.props.info.viwes.Mnombre || false,
			Mdevice: this.props.info.viwes.Mdevice || false,
			info: this.props.info,
			nombreWidget: this.props.info.tituloWidget || false,
			nombeDiposositivo: this.props.info.nombre || false,
			idDispositivo: this.props.info.device || false,
			unidad: this.props.info.unidad,
			valorMin: this.props.info.valorMin,
			valorMax: this.props.info.valorMax,
			index: this.props.index,
			background: this.props.info.background,
			containerRef: React.createRef(),
			gaugeSize: { width: 450, height: 450 },
			año: this.props.funcionF(this.props.info.ultimoDato),
			labels: {
				valueLabel: {
					formatTextValue: value => value + `${this.props.info.unidad}`,
					style: {
						fill: "#000000",
						textShadow: "none"
					}
				},
				tickLabels: {
					defaultTickValueConfig: {
						formatTextValue: value => value + `${this.props.info.unidad}`,
						style: {
							fill: "#000000",
							textShadow: "none"
						}
					}
				}
			}
		};
	}

	funcion = () => {
		this.eliminarWidget({ index: this.state.index, widget: this.state.info })
	}

	handleMouseOver = (event) => {
		event.target.style.cursor = 'pointer';
	}
	componentDidUpdate(prevProps) {
		if (prevProps.actualizar !== this.props.actualizar) {
			this.setState({
				Mnombre: this.props.info.viwes.Mnombre || false,
				Mdevice: this.props.info.viwes.Mdevice || false,
				info: this.props.info,
				nombreWidget: this.props.info.tituloWidget || false,
				nombeDiposositivo: this.props.info.nombre || false,
				idDispositivo: this.props.info.device || false,
				unidad: this.props.info.unidad,
				valorMin: this.props.info.valorMin,
				valorMax: this.props.info.valorMax,
				index: this.props.index,
				background: this.props.info.background || "#ffffff",
				labels: {
					valueLabel: {
						formatTextValue: value => value + `${this.props.info.unidad}`,
						style: {
							fill: "#000000",
							textShadow: "none"
						}
					},
					tickLabels: {
						defaultTickValueConfig: {
							formatTextValue: value => value + `${this.props.info.unidad}`,
							style: {
								fill: "#000000",
								textShadow: "none"
							}
						}
					}
				}
			});
		}
	}

	componentDidMount() {
		this.updateGaugeSize();
		const resizeObserver = new ResizeObserver(() => {
			this.updateGaugeSize();
		});
		if (this.state.containerRef.current) {
			resizeObserver.observe(this.state.containerRef.current);
		}
		this.setState({ resizeObserver });
	}

	componentWillUnmount() {
		if (this.state.resizeObserver) {
			this.state.resizeObserver.disconnect();
		}
	}

	updateGaugeSize = () => {
		if (this.state.containerRef.current) {
			const { width, height } = this.state.containerRef.current.getBoundingClientRect();
			this.setState({
				gaugeSize: { width, height },
				forceUpdate: !this.state.forceUpdate // Forzar la actualización del GaugeComponent
			});
		}
	};

	generateSubArcs = () => {
		const minValue = parseFloat(this.state.valorMin);
		const maxValue = parseFloat(this.state.valorMax);
		const range = maxValue - minValue;
		let step;
		if (range < 4) {
			step = range / 4
		} else {
			step = Math.floor(range / 4);
		}
		// Dividir en 4 partes (3 pasos)
		return [
			{ limit: minValue + step, color: '#EA4228', showTick: true },
			{ limit: minValue + 2 * step, color: '#F58B19', showTick: true },
			{ limit: minValue + 3 * step, color: '#F5CD19', showTick: true },
			{ limit: maxValue, color: '#5BE12C', showTick: true }
		];
	};

	render() {
		const { gaugeSize } = this.state;
		return (
			<Main background={this.state.background}>
				<IconoEdit>
					<EditIcon onClick={this.funcion} onMouseOver={this.handleMouseOver} />
				</IconoEdit>
				<>
					<Contenedor>
						{this.state.nombreWidget != false ? <Titulo>{this.state.nombreWidget}</Titulo> : null}
						{this.state.Mnombre ? <p>{this.state.nombeDiposositivo}</p> : null}
						{this.props.data != null && this.props.data != "" ?

							<div ref={this.state.containerRef} style={{ width: '100%', height: '100%' }}>
								<GaugeComponent
									minValue={parseFloat(this.state.valorMin)}
									maxValue={parseFloat(this.state.valorMax)}
									key={`${gaugeSize.width}-${gaugeSize.height}-${this.state.forceUpdate}-${this.state.unidad}`} // Usar key para forzar la actualización del componente
									arc={{ subArcs: this.generateSubArcs() }}
									labels={{
										valueLabel: {
											formatTextValue: value => value + ` ${this.state.unidad}`,
											style: { fill: "#000000", textShadow: "none" }
										},
										tickLabels: {
											defaultTickValueConfig: {
												formatTextValue: value => value + ` ${this.state.unidad}`,
												style: { fill: "#000000", textShadow: "none" }
											}
										}
									}}
									value={this.props.data}
									width={gaugeSize.width} // Ajustar el ancho dinámicamente
									height={gaugeSize.height} // Ajustar la altura dinámicamente
								/>
							</div>
							: <p>No han reportado</p>}
						<NombreD>{this.state.Mdevice ? `ID: ${this.state.idDispositivo}` : null}</NombreD>
						<Actalizacion className='widgets-font'>Ultima actualizacion: {this.state.año.año} {this.state.año.hora}</Actalizacion>
					</Contenedor>
				</>
			</Main>
		);
	}
}

export default gauge

