import React, { useState, useEffect } from "react";
import { Main, ContenedorTitulo, Titulo, ContenedorFechas, ContenedorFiltros, ContenedorF } from "./ElementosStyles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import InputLabel from "@mui/material/InputLabel";
import { Button } from "react-bootstrap";
import Tabla from "./Tabla/index";
import Autocomplete from '@mui/material/Autocomplete';
import API from "../../services/api-service";
import { useLocation } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';

const Index = () => {
    const [controladorC, setControladorC] = useState(false);
    const [fechaInicial, setFechaInicial] = useState(() => localStorage.getItem('fechaInicial') || (new Date().getTime() - 1000 * 60 * 60 * 24));
    const [fechaFinal, setFechaFinal] = useState(() => localStorage.getItem('fechaFinal') || new Date().getTime());
    const [estado, setEstado] = useState(localStorage.getItem('estado') || "Todos");
    const [mostrarProcesos, setMostrarProcesos] = useState(true);
    const itemsPerPageOptions = [5, 10, 20, 50, 100, 200];
    const [idSensor, setIdSensor] = useState(localStorage.getItem('idSensor') || "");
    const [idProducto, setIdProducto] = useState(localStorage.getItem('idProducto') || "");
    const [lugarInicio, setLugarInicio] = useState(localStorage.getItem('lugarInicio') || "");
    const [lugarFin, setLugarFin] = useState(localStorage.getItem('lugarFin') || "");
    const [data, setData] = useState([]);
    const [dataFiltrada, setDataFiltrada] = useState(data);
    const storedSelectedFilters = localStorage.getItem('selectedFilters');
    const initialSelectedFilters = storedSelectedFilters ? JSON.parse(storedSelectedFilters) : [];
    const [selectedFilters, setSelectedFilters] = useState(initialSelectedFilters);
    const [filtroProducto, setFiltroProducto] = useState(localStorage.getItem('filtroProducto') || "");
    const [dataPro, setDataPro] = useState([]);
    const query = new URLSearchParams(useLocation().search);
    const organizacion = query.get("organizacion");
    const localizacion = query.get("localizacion");

    let productos = dataPro.map(item => item.nombre);

    const handleFilterChange = (event, newValue) => {
        if (newValue === null) {
            setFiltroProducto("")
        } else {
            setFiltroProducto(newValue);
        }
    };

    const handleOnchange = val => {
        setSelectedFilters(val);

        if (!val.includes('Estado')) {
            setEstado('Todos');
        }
        if (!val.includes('ID sensor')) {
            setIdSensor('');
        }
        if (!val.includes('Nombre Producto')) {
            setFiltroProducto('');
        }
        if (!val.includes('idprod')) {
            setIdProducto('');
        }
        if (!val.includes('Lugar inicio')) {
            setLugarInicio('');
        }
        if (!val.includes('Lugar fin')) {
            setLugarFin('');
        }
    };

    const options = [
        { label: 'ID Sensor', value: 'ID sensor' },
        { label: 'Estado', value: 'Estado' },
        { label: 'Nombre Producto', value: 'Nombre Producto' },
        { label: 'ID Producto', value: 'idProd' },
        { label: 'Lugar Inicio', value: 'Lugar inicio' },
        { label: 'Lugar Finalización', value: 'Lugar fin' },
    ];

    const cambiarEstado = (e) => {
        const selectedEstado = e.target.value;
        setEstado(selectedEstado);

        if (selectedEstado === "Todos") {
            setDataFiltrada(data);
        } else {
            const filtered = data.filter(item => item.status === selectedEstado);
            setDataFiltrada(filtered);
        }
    };

    const columnValues = [
        { nColumna: 'ID Sensor', nValor: 'deviceID' },
        { nColumna: 'Nombre Producto', nValor: "producto" },
        { nColumna: 'ID Producto', nValor: "productoId" },
        { nColumna: 'Estado', nValor: "status" },
        { nColumna: 'Lugar Inicio', nValor: "lugarInicio" },
        { nColumna: 'Fecha Inicio', nValor: 'fechaInicial' },
        { nColumna: 'Lugar Finalización', nValor: 'lugarFinalizacion' },
        { nColumna: 'Fecha Final', nValor: 'fechaFinalizacion' },
        { nColumna: "Duración", nValor: "duracionLectura" },
        { nColumna: "Detalle", nValor: 'verProceso' },
        { nColumna: "Ultima actualización", nValor: 'ultimaActualizacion' }
    ];

    useEffect(async () => {
        try {
            const responsePro = await API.obtenerProductosFav();
            setDataPro(responsePro);
            const response = await API.consumirData(organizacion, localizacion, fechaInicial, fechaFinal);
            setData(response);
            filtrarData(response);
        } catch (error) {
            console.log(error);
        }
    }, [estado, idSensor, idProducto, filtroProducto, lugarInicio, lugarFin, controladorC]);

    useEffect(() => {
        localStorage.setItem('fechaInicial', fechaInicial);
        localStorage.setItem('fechaFinal', fechaFinal);
        localStorage.setItem('estado', estado);
        localStorage.setItem('idSensor', idSensor);
        localStorage.setItem('idProducto', idProducto);
        localStorage.setItem('lugarInicio', lugarInicio);
        localStorage.setItem('lugarFin', lugarFin);
        localStorage.setItem('filtroProducto', filtroProducto);
        localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
    }, [fechaInicial, fechaFinal, estado, idSensor, idProducto, lugarInicio, lugarFin, filtroProducto, selectedFilters]);

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload)
        return() => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }
    },[])

    const handleBeforeUnload = () => {
        localStorage.setItem('fechaInicial', new Date().getTime() - 1000 * 60 * 60 * 24);
        localStorage.setItem('fechaFinal', new Date().getTime());
        localStorage.setItem('estado', "");
        localStorage.setItem('idSensor', "");
        localStorage.setItem('idProducto', "");
        localStorage.setItem('lugarInicio', "");
        localStorage.setItem('lugarFin', "");
        localStorage.setItem('filtroProducto', "");
        localStorage.setItem('selectedFilters', []);
    };

    const filtrarData = (data) => {
        let filtered = data.filter(item => {
            let matchIDSensor = true;
            let matchLugarInicio = true;
            let matchLugarFin = true;
            let matchNombrePro = true;
            let matchidProd = true;

            if (selectedFilters.includes('ID sensor') && idSensor !== "") {
                matchIDSensor = item.deviceID.toLowerCase().startsWith(idSensor.toLowerCase());
            }

            if (selectedFilters.includes('Lugar inicio') && lugarInicio !== "") {
                matchLugarInicio = item.lugarInicio.toLowerCase().startsWith(lugarInicio.toLowerCase());
            }

            if (selectedFilters.includes('Lugar fin') && lugarFin !== "") {
                matchLugarFin = item.lugarFinalizacion.toLowerCase().startsWith(lugarFin.toLowerCase());
            }

            if (selectedFilters.includes('idProd') && idProducto !== ""){
                matchidProd = item.productoId.toLowerCase().startsWith(idProducto.toLowerCase());
            }

            if (selectedFilters.includes('Nombre Producto') && filtroProducto !== null) {
                matchNombrePro = item.producto.toLowerCase().includes(filtroProducto.toLowerCase());
            } else {
                matchNombrePro = data;
            }

            return matchIDSensor && matchLugarInicio && matchLugarFin && matchNombrePro && matchidProd;
        });

        if (selectedFilters.includes('Estado') && estado !== "Todos") {
            filtered = filtered.filter(item => item.status === estado);
        }

        setDataFiltrada(filtered);
    };

    const mostrarFechas = () => {
        setControladorC(!controladorC);
    };

    const transformarFechaInicial = (value) => {
        setFechaInicial(value.getTime());
    };

    const transformarFechaFinal = (value) => {
        setFechaFinal(value.getTime());
    };

    return (
        <>
            <Main>
                <ContenedorTitulo>Procesos</ContenedorTitulo>
                <ContenedorFechas>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Fecha Inicial"
                            value={new Date(parseInt(fechaInicial))}
                            onChange={(e) => transformarFechaInicial(e)}
                        />
                    </LocalizationProvider>
                </ContenedorFechas>
                <ContenedorFechas>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Fecha final"
                            value={new Date(parseInt(fechaFinal))}
                            onChange={(e) => transformarFechaFinal(e)}
                        />
                    </LocalizationProvider>
                </ContenedorFechas>
                <ContenedorFechas>
                    <Button onClick={(e) => mostrarFechas()}>
                        Buscar
                    </Button>
                </ContenedorFechas>
                <ContenedorFechas>
                    <FormControl>
                        <MultiSelect
                            onChange={handleOnchange}
                            placeholder={"Filtros"}
                            options={options}
                            defaultValue={selectedFilters}
                        />
                        <ContenedorFiltros>
                            {selectedFilters.includes('Estado') && (
                                <ContenedorFechas>
                                    <FormControl>
                                        <InputLabel>Estado</InputLabel>
                                        <Select
                                            defaultValue={estado}
                                            onChange={cambiarEstado}
                                        >
                                            <MenuItem value="Todos">Todos</MenuItem>
                                            <MenuItem value="Finalizado">Finalizado</MenuItem>
                                            <MenuItem value="En Proceso">En proceso</MenuItem>
                                        </Select>
                                    </FormControl>
                                </ContenedorFechas>
                            )}
                            {selectedFilters.includes('ID sensor') && (
                                <ContenedorFechas>
                                    <TextField
                                        label="ID sensor"
                                        value={idSensor}
                                        onChange={(e) => setIdSensor(e.target.value)}
                                    />
                                </ContenedorFechas>
                            )}
                            {selectedFilters.includes('Nombre Producto') && (
                                <ContenedorF>
                                    <Autocomplete
                                        value={filtroProducto}
                                        onChange={handleFilterChange}
                                        options={productos}
                                        noOptionsText="No existe producto"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Producto"
                                                variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        )}
                                    />
                                </ContenedorF>

                            )}
                            {selectedFilters.includes('Lugar inicio') && (
                                <ContenedorFechas>
                                    <TextField
                                        label="Lugar inicio"
                                        value={lugarInicio}
                                        onChange={(e) => setLugarInicio(e.target.value)}
                                    />
                                </ContenedorFechas>
                            )}
                            {selectedFilters.includes('Lugar fin') && (
                                <ContenedorFechas>
                                    <TextField
                                        label="Lugar finalización"
                                        value={lugarFin}
                                        onChange={(e) => setLugarFin(e.target.value)}
                                    />
                                </ContenedorFechas>
                            )}
                            {selectedFilters.includes('idProd') && (
                                <ContenedorFechas>
                                    <TextField
                                        label="ID Producto"
                                        value={idProducto}
                                        onChange={(e) => setIdProducto(e.target.value)}
                                    />
                                </ContenedorFechas>
                            )}
                        </ContenedorFiltros>
                    </FormControl>
                </ContenedorFechas>
                {data.length > 0 ? <Tabla data={dataFiltrada} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnValues} /> : null}
            </Main>
        </>
    );
};

export default Index;
