import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../../services/api-service";
import { Button, Table } from "react-bootstrap";
import { ContenedorDatos } from "../Historico/ElementosStyles";

const ShowAutomaticReport = ({ changeTable }) => {
  const query = new URLSearchParams(useLocation().search);
  const organizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const [automaticReports, setAutomaticReports] = useState([]);
  const [deleteAction, setDeleteAction] = useState(true);

  useEffect(() => {
    const info = {
      organizacion,
      localizacion,
    };
    API.getAutomaticReports(info)
      .then((res) => {
        const { data } = res;
        setAutomaticReports(data);
      })
      .catch((error) => {
        console.error("Error fetching reports:", error);
      });
  }, [organizacion, localizacion, deleteAction, changeTable]);

  const deleteAutomaticReport = (data) => {
    API.deleteAutomaticReport({ id: data._id })
      .then(() => {
        deleteAction ? setDeleteAction(false) : setDeleteAction(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <ContenedorDatos>
        <h5>
          <b>Reportes Programados</b>
        </h5>
        <Table responsive>
          <thead>
            <tr>
              <th>Datos del Correo</th>
              <th>Datos de Envio</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {automaticReports && automaticReports.length > 0 ? (
              automaticReports.map((report, index) => (
                <tr key={index}>
                  <td>
                    <b>Correo: </b>{report.correo.email}
                    <br />
                    <b>Asunto: </b>{report.correo.asunto}
                    <br />
                    <b>Documentos:</b>
                    {report.correo.reportePdf ? " Reporte PDF" : ""}
                    {report.correo.reporteExcel ? " Reporte Excel" : ""}
                  </td>
                  <td>
                    <b>Frecuencia: </b>{report.programacion.frecuencia}
                    <br />
                    <b>Día: </b>{report.programacion.dia}
                    <br />
                    <b>Tiempo de la Data: </b>
                    {report.programacion.tiempoData < 30
                      ? (report.programacion.tiempoData == 1 ? `Ultimo Día` : `Ultimos ${report.programacion.tiempoData} Días`)
                      : (report.programacion.tiempoData - 30 == 1 ? `Ultimo Mes` : `Ultimos ${report.programacion.tiempoData - 30} Meses`)}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => deleteAutomaticReport(report)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No hay reportes automáticos disponibles</td>
              </tr>
            )}
          </tbody>
        </Table>
      </ContenedorDatos>
    </>
  );
};

export default ShowAutomaticReport;
