import React, { useState, useMemo } from 'react'
import TextField from "@mui/material/TextField";
import { SketchPicker as Picker } from 'react-color';
import Api from '../../../services/api-service.js';
import { ContenedorTitulo, Main, Opciones, BotonGuardar, ContenedorBotoness, ContenedorBotones } from '../Elements'
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const decimalToHex = (alpha) =>
    alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

const Texto = ({ data, saveData, funcionEliminar, valorActual }) => {
    const [tituloWidget, setTituloWidget] = useState(valorActual.widget.data)
    const [color, setColor] = useState(valorActual.widget.backgroundColor);
    
    const hexColor = useMemo(() => {
        if (typeof color === 'string') {
            return color;
        }
        return `${color?.hex}${decimalToHex(color?.rgb?.a)}`;
    }, [color]);


    const GuardarData = () => {
        let maxY
        let cantidad
        let maxX
        if (Object.keys(data).length == 4) {
            maxY = 0
            cantidad = 1
            data.posiciones = []
            data.identificadores = []
        } else {
            maxY = data.posiciones[0].y;
            maxX = data.posiciones[0].x;
            cantidad = data.posiciones.length + 1;
            cantidad = cantidad.toString();

            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y > maxY) {
                    maxY = data.posiciones[i].y;
                }
            }
            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y == maxY) {
                    if (data.posiciones[i].x > maxX) {
                        maxX = data.posiciones[i].x;
                    }
                }
            }
        }

        if (maxX > 4) {
            maxX = 0
            maxY = maxY + 1
        } else {
            maxX = maxX + 1
        }

        let nombres = {
            name: "Texto",
            backgroundColor: hexColor,
            data: tituloWidget
        };

        let newWidget = {
            w: 7,
            h: 1,
            x: maxX,
            y: maxY,
            i: cantidad,
            moved: false,
            static: false,
        };
        data.posiciones.push(newWidget);
        data.identificadores.push(nombres);
        saveData(data)
    };

    const VerificarTituloWidget = (e) => {
        const inputValue = e.target.value;
        setTituloWidget(inputValue)
    }

    const enviarEliminar = () => {
        funcionEliminar()
    }

    const submitForm = () => {
        if (data.identificadores[valorActual.index].data != tituloWidget) {
            data.identificadores[valorActual.index].data = tituloWidget
        }
        if (data.identificadores[valorActual.index].backgroundColor != color) {
            data.identificadores[valorActual.index].backgroundColor = color.hex
        }

        Api.actualizarWidget(data).then(
            result => {
                saveData()
            }, error => {
                alert(error)
            }
        )
        /*
        toast.promise(
            Api.actualizarWidget(data).then((response) => {
              saveData()
            }),
            {
              loading: "Cargando...",
              success: <b>Widget Actualizado.!</b>,
              error: (err) => <b>Error al cargar datos!</b>,
            }
          );*/


    }

    return (
        <>
            <Main>
                <ContenedorTitulo>
                    Parámetros
                </ContenedorTitulo>
                <TextField
                    label="Titulo Widget"
                    value={tituloWidget}
                    onChange={VerificarTituloWidget}
                />
                <Opciones>
                    <Picker color={hexColor} onChange={setColor} />
                </Opciones>

                <ContenedorBotones>
                    <ContenedorBotoness>
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" onClick={submitForm}>
                                Actualizar
                            </Button>
                            <Button variant="outlined" color="error" onClick={enviarEliminar}>
                                Eliminar
                            </Button>
                        </Stack>
                    </ContenedorBotoness>
                </ContenedorBotones>
            </Main>
            {/* <button onClick={GuardarData}>Aplasta aqui</button> */}
        </>
    )
}

export default Texto