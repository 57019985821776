import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "../../../components/Entradas/Select";
import DisSelect from "../../../components/Entradas/Disabled Select/index";
import SelectDimension from "../../../components/Entradas/SelectDimension/index";
import SelectOperador from "../../../components/Entradas/SelectOperador/index";
import Input2 from "../../../components/Entradas/Input2/index";
import Swal from "sweetalert2";
import Auth from "../../../services/auth-service.js";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  ContenedorTabla,
  ContenedorTitulo,
  ContPa1,
  ContPa2,
  ContPa3,
  ContPa4,
  ContenedorCategorias,
  Main,
  NavbarTabla,
  Titulo,
  BotonRetroceder2,
  BotonSiguiente2,
  ContenedorCondicionales,
  ContenedorAcciones,
  DivisorU,
  DivisorS,
  DivisorP,
  ContText,
} from "../AlertasElements";
import { CompareTwoTone } from "@material-ui/icons";

let Operadores = [
  {
    value: "&&",
    label: "And",
  },
  {
    value: "||",
    label: "Or",
  },
];

let Comparadores2 = [
  {
    value: "false",
    label: "Falso (Anormal)",
  },
  {
    value: "true",
    label: "Verdadero (Normal)",
  },
];

let Comparadores = [
  {
    value: "<=",
    label: "Menor igual",
  },
  {
    value: "<",
    label: "Menor",
  },
  {
    value: ">=",
    label: "Mayor igual",
  },
  {
    value: ">",
    label: "Mayor",
  },
  {
    value: "==",
    label: "Igual",
  },
];

let Tipo = [
  {
    value: "Comparador",
    label: "Comparador",
  },
  {
    value: "Inactividad",
    label: "Inactividad",
  },
];

let Tipo2 = [
  {
    value: "Operador",
    label: "Operador",
  },
];



const Condiciones = () => {
  const [controTf, setControTf] = useState(false);

  const [postData, setData] = useState({
    condicionales: [{ condicional: "" }],
    connectors: [],
  });

  const [documento, setDocumento] = useState({});

  const [inactividadTiempo, setInactividadTIempo] = useState(null);
  const [comparativoV, setComparativoV] = useState(null);
  const [comparativoC, setComparativoC] = useState(null);
  const [comparativoVr, setComparativoVr] = useState(null);
  const [operadorT, setOperadorT] = useState(null);

  const [auxx, setAux] = useState(false);
  const [variable, setVariable] = useState(null);

  //Hooks selects, inputs
  const [tipos, setTipos] = useState([]);
  const [inactivo, setInactivo] = useState([]);
  const [comparativo, setComparativo] = useState([]);
  const [operador, setOperador] = useState([]);
  const [inputForm, setInputForm] = useState(null);
  const [inputFor, setInputFor] = useState(null);

  const [postCondicional, setCondicional] = useState({});
  const [postConector, setConector] = useState([]);

  const [arrePOst, arrSetPost] = useState([]);
  const [dataPost, setDataPost] = useState([]);
  const [condi, setCondi] = useState([]);
  let history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const obteLocalizacion = query.get("localizacion");

  function eliminar(i) {
    let arregloOperadores = []
    let arregloCondicional = []
    delete (condi[i])
    const resultado = condi.filter(numbers => numbers != 'undefined');
    resultado.map((index) => {
      arregloOperadores.push(index)
    })
    setCondi(arregloOperadores)


    dataPost.map((index, ite) => {
      if (ite != i) {
        arregloCondicional.push(index)
      }
    })

    // const resultado2 = dataPost.filter(numbers => numbers != null||numbers !=undefined);
    // resultado2.map((index)=>{
    //   arregloCondicional.push(index)
    // })

    setDataPost(arregloCondicional)

  }

  function nextPage() {

    if (dataPost.length <= 0) {
      Swal.fire("Debe agregar un condicional", "", "error");
    } else {
      if (dataPost.length == condi.length) {
        Swal.fire("No puede dejar un operador al final", "", "error");
      } else {
        // Auth.saveAlert({})
        documento.connectors = condi
        documento.condicionales = dataPost
        Auth.saveAlert(documento)
        Swal.fire("Guardado exitosamente", "", "success");

        history.push(`/admin/messageAlert?organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`);
      }

    }
  }
  function prevPage() {

    let url = `/admin/createAlert?organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`;
    history.push(url);
  }

  function validarCuadro(valor, tipo) {
    let aux = {};
    let dato;
    var expRegNum = /^[0-9]+$/
    switch (tipo) {
      case "Comparador_3":
        if (
          comparativoV != null &&
          comparativoC != null &&
          comparativoVr != null
        ) {
          dato = `@/${comparativoV}/@${comparativoC}${comparativoVr}`;
          Swal.fire("Guardado exitosamente", "", "success");
          aux.condicional = dato;
          dataPost.push(aux);
          setDataPost(dataPost);
          setAux(!auxx); //Para que se quite la seleccion despues de guardar
          setTipos([]); //Se vuelve el valor inicial de tipo de variable
          setComparativoV(null);
          setComparativoC(null);
          setComparativoVr(null);
        } else {
          Swal.fire("Los campos deben ser rellenados", "", "error");
        }
        break;
      case "Comparador_2":
        if (comparativoV != null &&
          comparativoC != null) {
          dato = `@/${comparativoV}/@==${comparativoC}`;
          Swal.fire("Guardado exitosamente", "", "success");
          aux.condicional = dato;
          dataPost.push(aux);
          setDataPost(dataPost);
          setAux(!auxx); //Para que se quite la seleccion despues de guardar
          setTipos([]); //Se vuelve el valor inicial de tipo de variable
          setComparativoV(null);
          setComparativoC(null);
          setComparativoVr(null);
        } else {
          Swal.fire("Los campos deben ser rellenados", "", "error");
        }
        break;
      case "Inactividad":
        if (inactividadTiempo != null) {
          if (expRegNum.test(inactividadTiempo)) {
            dato = `@/inactividad/@==${inactividadTiempo}`;
            aux.condicional = dato;
            dataPost.push(aux);
            setDataPost(dataPost);
            setAux(!auxx); //Para que se quite la seleccion despues de guardar
            setTipos([]); //Se vuelve el valor inicial de tipo de variable
            setInactividadTIempo(null);
            Swal.fire("Guardado exitosamente", "", "success");
          } else {
            Swal.fire("Debe Ingresar un numero", "", "error");
          }
        } else {
          Swal.fire("Los campos deben ser rellenados", "", "error");
        }
        break;
      case "Operador":
        if (operadorT != null && operadorT != "") {
          condi.push(operadorT)
          setCondi(condi)
          setAux(!auxx)
          setTipos([]); //Se vuelve el valor inicial de tipo de variable
          setOperadorT(null);
          Swal.fire("Guardado exitosamente", "", "success");
        } else {
          Swal.fire("Los campos deben ser rellenados", "", "error");
        }
        break;
    }
  }

  useEffect(async () => {
    let prueba

    prueba = Auth.getAlert()
    setDocumento(prueba)

    let variables = Auth.getVariables();
    let arreglo = [];
    variables.map((index) => {
      let Documento = {};
      Documento.value = index.variable;
      Documento.label = index.variable;
      Documento.tipo = index.tipo;
      arreglo.push(Documento);
    });
    arrSetPost(arreglo);
  }, [auxx, dataPost, condi]);

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Condicionales</Titulo>
        </ContenedorTitulo>
        <ContenedorTabla>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 2, width: "20ch" },
            }}
            noValidate
            autoComplete="off"
          >

            {dataPost.length == condi.length ? <><Select
              message="Operación"
              value={tipos}
              setFunction={setTipos}
              data={Tipo}
              id="simple-disabled"
            ></Select></> : <><Select
              message="Operación"
              value={tipos}
              setFunction={setTipos}
              data={Tipo2}
              id="simple-disabled"
            ></Select></>}



          </Box>
          {tipos == "Inactividad" ? (
            <ContPa1>
              <ContPa2>
                <DisSelect
                  message="Inactividad"
                  value={inactivo}
                  setFunction={setInactivo}
                  data={Tipo}
                  id="demo-simple-select-disabled"
                ></DisSelect>
              </ContPa2>
              <ContText>
                <>Por</>
              </ContText>
              <ContText>
                <Input2
                  message="Tiempo de espera "
                  value={inactividadTiempo}
                  setFunction={setInactividadTIempo}
                  id="outlined-disabled"
                />
              </ContText>
              <ContText>
                <>[S]</>
              </ContText>
              <ContPa2>
                <ContText>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      onClick={() =>
                        validarCuadro(inactividadTiempo, "Inactividad")
                      }
                    >
                      Guardar
                    </Button>
                  </Stack>
                </ContText>
              </ContPa2>
            </ContPa1>
          ) : (
            <></>
          )}

          {tipos == "Comparador" ? (
            <ContPa1>
              <ContPa2>
                <SelectOperador
                  message="Comparadores"
                  value={comparativoV}
                  setFunction={setComparativoV}
                  setValor={setControTf}
                  data={arrePOst}
                  id="demo-simple-select-disabled"
                ></SelectOperador>
              </ContPa2>
              <ContText>
                <>Es</>
              </ContText>
              {controTf ? (
                <>
                  <ContPa2>
                    <SelectDimension
                      message="Comparador"
                      value={comparativoC}
                      setFunction={setComparativoC}
                      data={Comparadores2}
                      id="demo-simple-select-disabled"
                    ></SelectDimension>
                  </ContPa2>
                  <ContPa2>
                    <ContText>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="contained"
                          onClick={() => validarCuadro(comparativoC, "Comparador_2")}
                        >
                          Guardar
                        </Button>
                      </Stack>
                    </ContText>
                  </ContPa2>
                </>
              ) : (
                <>
                  <ContPa2>
                    <SelectDimension
                      message="Comparador"
                      value={comparativoC}
                      setFunction={setComparativoC}
                      data={Comparadores}
                      id="demo-simple-select-disabled"
                    ></SelectDimension>
                  </ContPa2>
                  <ContText>
                    <> A </>
                  </ContText>
                  <ContText>
                    <Input2
                      message="Valor"
                      value={comparativoVr}
                      setFunction={setComparativoVr}
                      id="outlined-disabled"
                    />
                  </ContText>
                  <ContPa2>
                    <ContText>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="contained"
                          onClick={() => validarCuadro(comparativoC, "Comparador_3")}
                        >
                          Guardar
                        </Button>
                      </Stack>
                    </ContText>
                  </ContPa2>
                </>
              )}

            </ContPa1>
          ) : (
            <></>
          )}
          {tipos == "Operador" ? (
            <ContPa1>
              <ContPa2>
                <SelectDimension
                  message="Operadores"
                  value={operadorT}
                  setFunction={setOperadorT}
                  data={Operadores}
                  id="demo-simple-select-disabled"
                ></SelectDimension>
              </ContPa2>

              <ContPa2>
                <ContText>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      onClick={() => validarCuadro(operadorT, "Operador")}
                    >
                      Guardar
                    </Button>
                  </Stack>
                </ContText>
              </ContPa2>
            </ContPa1>
          ) : (
            <></>
          )}

          {dataPost.map((index, i) =>
            index.condicional.slice(2, index.condicional.indexOf("/@")) ==
              "Inactividad" ? (
              <>
                <ContText>
                  {" "}
                  {index.condicional.slice(
                    2,
                    index.condicional.indexOf("/@")
                  )}{" "}
                  por{" "}
                  {index.condicional.slice(index.condicional.indexOf("/@") + 4)}{" "}
                  minutos
                  <Button onClick={() => eliminar(i)}>Eliminar</Button>{" "}
                </ContText>
                <p>
                  <ContText>{condi[i]}X</ContText>
                </p>
              </>
            ) : (

              <>
                <p>
                  {index.condicional.slice(2, index.condicional.indexOf("/@"))}{" "}
                  es{" "}
                  {index.condicional.slice(index.condicional.indexOf("/@") + 2, index.condicional.indexOf("/@") + 4) == '==' ? <>igual a{" "}{index.condicional.slice(index.condicional.indexOf("/@") + 4)}</> : index.condicional.slice(index.condicional.indexOf("/@") + 2, index.condicional.indexOf("/@") + 4) == '<=' ? <>menor igual a{" "}{index.condicional.slice(index.condicional.indexOf("/@") + 4)}</> : index.condicional.slice(index.condicional.indexOf("/@") + 2, index.condicional.indexOf("/@") + 3) === '<' ? <>menor a{" "}{index.condicional.slice(index.condicional.indexOf("/@") + 3)}</> : index.condicional.slice(index.condicional.indexOf("/@") + 2, index.condicional.indexOf("/@") + 4) == '>=' ? <>mayor igual a{" "}{index.condicional.slice(index.condicional.indexOf("/@") + 4)}</> : index.condicional.slice(index.condicional.indexOf("/@") + 2, index.condicional.indexOf("/@") + 3) == '>' ? <>mayor a{" "}{index.condicional.slice(index.condicional.indexOf("/@") + 3)}</> : null}


                  {index.condicional.slice(index.condicional.indexOf("/@") + 4) == 'false' ? <>Falso (Anormal)</> : null}
                  {index.condicional.slice(index.condicional.indexOf("/@") + 4) == 'true' ? <>Verdadero (Normal)</> : null}

                  <Button onClick={() => eliminar(i)}>Eliminar</Button>{" "}
                </p>
                <p>
                  {condi[i] == '&&' ? <>AND</> : null}
                  {condi[i] == '||' ? <>OR</> : null}
                </p>
              </>
            )
          )}
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          <BotonRetroceder2 onClick={prevPage}>Atrás</BotonRetroceder2>
          <BotonSiguiente2 onClick={() => nextPage()}>Siguiente</BotonSiguiente2>
          </div>
        </ContenedorTabla>
      </Main>
    </>
  );
};

export default Condiciones;
