import React, { useState, useEffect, useRef } from "react";
import BatteryGauge from "react-battery-gauge";
import {
  Actalizacion,
  Contenedor,
  ContenedorWa,
  IconoEdit,
  ImagenWid,
  IndicadorT,
  Main,
  NombreD,
  StyledReactGridItem,
  Testing,
  Titulo,
} from "./ElementsDisplay";

const App = (props) => {

  let displayValue;

  // Verifica si props.data tiene 4 dígitos
  if (props.data >= 1000 && props.data < 10000) {
    let battery = props.data / 1000;
    battery = (battery / 3.7) * 100;
    displayValue = battery.toFixed(2);
  } else {
    displayValue = props.data;
  }

  return (
    <>
      <BatteryGauge value={displayValue} style={{height: "100px"}} />
    </>
  );
};

export default App;
