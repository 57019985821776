import React from "react";
import moment from "moment";
import { Line } from 'react-chartjs-2';
import { Resizable } from 'react-resizable';
import 'chartjs-adapter-moment';
import { Contenedor, Main } from './ElementsDisplay';

export default function App({ info }) {
  let options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          tooltipFormat: 'll HH:mm'
        }
      }
    },
    maintainAspectRatio: false
  };

  const adjustedEjex = info.map((fecha) => {
    const fechaD = fecha.fechaCompleta || fecha.fecha;
    const date = new Date(fechaD);
    date.setHours(date.getHours() + 5);
    return date;
  });

  const valorHumedad = info.map((valor) => {
    return valor.humidity || valor.humedad || valor.Hambiente;
  });

  const data = {
    labels: adjustedEjex,
    datasets: [
      {
        label: "Humedad",
        data: valorHumedad,
        fill: true,
        pointRadius: 1.8,
        backgroundColor: "rgba(111, 152, 234,0.2)",
        borderColor: "rgba(111, 152, 234,1)"
      }
    ]
  };

  options.plugins = {
    tooltip: {
      callbacks: {
        label: (context) => {
          const index = context.dataIndex;
          const humedad = valorHumedad[index];
          return `Humedad: ${humedad}`;
        }
      }
    }
  };

  return (
    <>
      {info.length > 0 ? <>
        <Main>
          <Contenedor>
            <Resizable>
              <Line data={data} options={options} />
            </Resizable>
          </Contenedor>
        </Main>
      </> : <p>No hay Datos</p>}
    </>
  );
}
