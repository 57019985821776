import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Resizable } from 'react-resizable';
import 'chart.js/auto';
import { Contenedor, Main } from '../LineChart/ElementsDisplay';


const DesconexionChart = ({ data }) => {
    const addFiveHours = (date) => {
        const newDate = new Date(date);
        newDate.setHours(newDate.getHours() + 5);
        return newDate;
    };

    const desconexiones = data.map(item => {
        const fechaDesconexion = addFiveHours(new Date(item.fechaDesconexion));
        const fechaConexion = addFiveHours(new Date(item.fechaConexion));
        const duracion = (fechaConexion - fechaDesconexion) / 60000; // Duración en segundos
        return {
            id: item._id,
            idSirena: item.idSirena,
            fechaDesconexion: fechaDesconexion,
            fechaConexion: fechaConexion,
            duracion: duracion,
        };
    });

    desconexiones.sort((a, b) => a.fechaDesconexion - b.fechaDesconexion);

    const chartData = {
        labels: desconexiones.map(d => d.fechaDesconexion.toLocaleString()), // Convertimos las fechas a string
        datasets: [
            {
                label: 'Duración de Desconexión (min)',
                data: desconexiones.map(d => d.duracion),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <>
            {data.length > 0 ? <>
                <Main>
                    <Contenedor>
                        <Resizable>
                            <Bar data={chartData} />
                        </Resizable>
                    </Contenedor>
                </Main>
            </> : <p>No hay Datos</p>}
        </>
    );
};

export default DesconexionChart;
