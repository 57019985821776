import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useHistory } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import Swal from 'sweetalert2'
import {
    Main,
    ContenedorTitulo,
    Titulo,
    ContenedorCategorias,
    Categorias,
    Example,
    CTituloModal,
    TituloModal,
    ContenedorBotones,
    ContenedorBotoness,
    MenuHamb,
    IcoHam,
    TituloHam,
    ContenedorAdd,
    TituloAdd,
    WidgetAdd,
    ContenedorTabla,
} from "./DispositivosElements";
import AUTH from "../../services/auth-service";
import API from "../../services/api-service";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Dispositivo',
    },
    {
        id: 'calories',
        numeric: true,
        disablePadding: false,
        label: 'Id Dispositivo',
    },
    {
        id: 'fat',
        numeric: true,
        disablePadding: false,
        label: '',
    },


];
function createData(id, name, calories, fat) {
    return {
        id,
        name,
        calories,
        fat,

    };
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        // onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell padding="checkbox">
                    {/* <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> */}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}
function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        DISPOSITIVOS
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        </>

    );
}
// EnhancedTableHead.propTypes = {
//     numSelected: PropTypes.number.isRequired,
//     onRequestSort: PropTypes.func.isRequired,
//     onSelectAllClick: PropTypes.func.isRequired,
//     order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//     orderBy: PropTypes.string.isRequired,
//     rowCount: PropTypes.number.isRequired,
// };
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
export default function EnhancedTable() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [Dispositivos, setDispositivos] = useState([]);
    const [DispositivoE, setDispositivoE] = useState("");
    const [datoTabla, setDatoTabla] = useState([]);
    const [dataTable2, setDataTable2] = useState([])
    const [data, setData] = useState([]);
    const query = new URLSearchParams(useLocation().search);
    const organizacionn = query.get("organizacion");
    const localizacionn = query.get("localizacion");
    const [loading, setLoading] = useState(true);

    const ConsumirDispositivo = (valor) => {
        setDispositivoE(valor);
        let Data = {
            organizacion: organizacionn,
            localizacion: localizacionn,
            tipoDispositivo: valor,
        };

        const getData = async () => {
            try {
                const response = await API.obtenerDispositivosD(Data)
                await setDataTable2(response)
            } catch (error) {
                console.log(error);
            }
        }
        getData()
    };

    function consumir() {

        setData([]);
        let data = {
            organizacion: organizacionn,
            localizacion: localizacionn,
        };
        // API.getAllDBD(data)
        //   .then((response) => {
        //     if (Object.keys(response).length > 0) {
        //       setDispositivos(response);
        //       setLoading(false); // Marca la carga como completa
        //     }
        //   })
        //   .catch((error) => {
        //     console.error('Error al obtener datos:', error);
        //     setLoading(false); // Marca la carga como completa aunque haya error
        //   });

        let organizaciones = AUTH.getOrganizaciones();
        organizaciones.map((index, i) => {
            if (index.organizacion == organizacionn) {
                index.localizacion.map((localizacion, j) => {
                    if (localizacion.localiza == localizacionn) {
                        let documento = {};
                        localizacion.configuraciones.map((confi, k) => {
                            documento.label = confi.nombre;
                            documento.value = confi.catalogo;
                            Dispositivos.push(documento);
                            documento = {}
                        });
                    }
                });
            }
        });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = dataTable2.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty dataTable2.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataTable2.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(dataTable2, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, dataTable2],
    );

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    const history = useHistory()
    const [datosVOne, setDatosVone] = useState([])

    const handleOpen = (row) => {
        Swal.fire({
            title: "Confirmar operación",
            text: "Edisión del dispositivo",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#1976d2",
            cancelButtonColor: "#d33",
        }).then(result => {
            if (result.isConfirmed) {


                AUTH.saveVariablesSinteticas(null)
                AUTH.saveVariablesSinteticas(row)
                history.push(`/admin/viewOneDevice?organizacion=${organizacionn}&localizacion=${localizacionn}&deviceID=${row.deviceID}`)
            }
        })
    };
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 20,
        p: 4,
    };

    useEffect(() => {
        consumir();
    }, []);

    return (
        <Main>
            <ContenedorTitulo>
                <Titulo>
                    DISPOSITIVOS
                </Titulo>
            </ContenedorTitulo>
            <ContenedorCategorias>
                <Categorias>

                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "30ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Tipo de Dispositivo"
                            value={Dispositivos.value}
                            onChange={(e) => ConsumirDispositivo(e.target.value)}
                        >
                            {Dispositivos.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Categorias>
            </ContenedorCategorias>
            <ContenedorTabla>




                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    // numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    // onSelectAllClick={handleSelectAllClick}
                                    // onRequestSort={handleRequestSort}
                                    rowCount={Dispositivos.length}
                                />
                                {dataTable2.length > 0 ? <TableBody>
                                    {visibleRows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow key={row.deviceID}>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">{row.deviceID}</TableCell>
                                                <TableCell align="right">{<MoreVertOutlinedIcon onClick={(e) => handleOpen(row)} />}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody> : null}

                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={dataTable2.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <FormControlLabel
                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                        label="Dense padding"
                    />
                </Box>
            </ContenedorTabla>
        </Main>
    );
}