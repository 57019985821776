import React from 'react'
import Bateria from "../Images/bateria.png"
import Humedad from "../Images/humedad.png"
import LineChart from "../Images/Line-chart.png"
import Metrica from "../Images/metrica.png"
import Temperatura from "../Images/temperatura.png"
import Texto from "../Images/texto.png"
import Barra from "./BarrasWid"
import EntradaT from "../Images/EntradaT.png"
import { ContenedorWi, ImagenWid, LeyendaD, Widg } from "../../../views/Dashboard/DashboardElements";

const Sidebar = ({ setWidget, setTest}) => {
    return (
        <>
            <Barra Titulo="Metrica">
                <ContenedorWi>
                    <Widg onClick={() => {setWidget("Humedad"); setTest("")}}>
                        <ImagenWid src={Humedad}></ImagenWid>
                    </Widg>
                    <LeyendaD>Indicador</LeyendaD>
                </ContenedorWi>
                <ContenedorWi>
                    <Widg onClick={() => {setWidget("Temperatura"); setTest("")}}>
                        <ImagenWid src={Temperatura}></ImagenWid>
                    </Widg>
                    <LeyendaD>Termómetro</LeyendaD>
                </ContenedorWi>
                <ContenedorWi>
                    <Widg onClick={() => {setWidget("Batería"); setTest("")}}>
                        <ImagenWid src={Bateria}></ImagenWid>
                    </Widg>
                    <LeyendaD>Bateria</LeyendaD>
                </ContenedorWi>
                <ContenedorWi>
                    <Widg onClick={() => {setWidget("Gauge"); setTest("")}}>
                        <ImagenWid src={Humedad}></ImagenWid>
                    </Widg>
                    <LeyendaD>Gauge</LeyendaD>
                </ContenedorWi>
            </Barra>

            <Barra Titulo="Graficos">
                <ContenedorWi>
                    <Widg onClick={() => {setWidget("LineChart"); setTest("")}}>
                        <ImagenWid src={LineChart}></ImagenWid>
                    </Widg>
                    <LeyendaD>Line chart</LeyendaD>
                </ContenedorWi>
                <ContenedorWi>
                    <Widg onClick={() => {setWidget("Metrica"); setTest("")}}>
                        <ImagenWid src={Metrica}></ImagenWid>
                    </Widg>
                    <LeyendaD>Bar chart</LeyendaD>
                </ContenedorWi>
            </Barra>

            <Barra Titulo="Display">
                <ContenedorWi>
                    <Widg onClick={() => {setWidget("Texto"); setTest("")}}>
                        <ImagenWid src={Texto}></ImagenWid>
                    </Widg>
                    <LeyendaD>Texto</LeyendaD>
                </ContenedorWi>
                <ContenedorWi>
                    <Widg onClick={() => {setWidget("Entrada"); setTest("")}}>
                        <ImagenWid src={EntradaT}></ImagenWid>
                    </Widg>
                    <LeyendaD>Entrada</LeyendaD>
                </ContenedorWi>
                <ContenedorWi>
                    <Widg onClick={() => {setWidget("LineChart"); setTest("test")}}>
                        <ImagenWid src={EntradaT}></ImagenWid>
                    </Widg>
                    <LeyendaD>Max/Min</LeyendaD>
                </ContenedorWi>
            </Barra>


            {/* <div style={{ backgroundColor: "rgba(230, 230, 250, 0.212)", width: 280, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <br></br>
            <h3 style={{ color: "black", margin: 10 }}>Widgets</h3>

            <i onClick={() => setWidget("LineChart")}>
                <img width={40} height={40} src={LineChart}></img>
            </i>
            <i onClick={() => setWidget("Metrica")}>
                <img width={40} height={40} src={Metrica}></img>
            </i>
            <i onClick={() => setWidget("Humedad")}>
                <img width={40} height={40} src={Humedad}></img>
            </i>
            <i onClick={() => setWidget("Temperatura")}>
                <img width={40} height={40} src={Temperatura}></img>
            </i>
            <i onClick={() => setWidget("Bateria")}>
                <img width={40} height={40} src={Bateria}></img>
            </i>
            <i onClick={() => setWidget("Texto")}>
                <img width={40} height={40} src={Texto}></img>
            </i>

            </div> */}
        </>)
}

export default Sidebar