import React, { useState, useMemo } from 'react'
import TextField from "@mui/material/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Informa from "../../Informacion/index";
import Checkbox from '@material-ui/core/Checkbox';
import Api from '../../../services/api-service.js'
import { SketchPicker as Picker } from 'react-color';
import { BotonGuardar, ContenedorTitulo, Main, Opciones, ContenedorBotoness, ContenedorBotones } from '../Elements'
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const Temperatura = ({ data, valorWid, device, variable, DispositivoE, saveData, valorActual, funcionEliminar, children }) => {

    const [tituloWidget, setTituloWidget] = useState(valorActual.widget.tituloWidget)
    const [vNombreDispotivo, setVNombreDispotivo] = useState(valorActual.widget.viwes.Mnombre)
    const [vIdDispositivo, setVIdDispositivo] = useState(valorActual.widget.viwes.Mdevice)
    const [puntoMaximo, setPuntoMaximo] = useState(valorActual.widget.puntoMaximo)
    const [puntoMinimo, setPuntoMinimo] = useState(valorActual.widget.puntoMinimo)
    const [tiempoReporte, setTiempoReporte] = useState(valorActual.widget.reporte)
    const [backgroundColor, setBackgroudColor] = useState(valorActual.widget.background)
    

    const GuardarData = () => {
        let Views = {
            "Mdevice": false,
            "Mnombre": false
        }
        let maxY
        let cantidad
        let maxX
        if (Object.keys(data).length == 4) {
            maxY = 0
            cantidad = 1
            data.posiciones = []
            data.identificadores = []
        } else {
            maxY = data.posiciones[0].y;
            maxX = data.posiciones[0].x;
            cantidad = data.posiciones.length + 1;
            cantidad = cantidad.toString();

            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y > maxY) {
                    maxY = data.posiciones[i].y;
                }
            }
            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y == maxY) {
                    if (data.posiciones[i].x > maxX) {
                        maxX = data.posiciones[i].x;
                    }
                }
            }
        }

        if (maxX > 4) {
            maxX = 0
            maxY = maxY + 1
        } else {
            maxX = maxX + 1
        }

        let nombres = {
            name: valorWid,
            device: device,
            variable: variable,
            typeDevice: DispositivoE,
            puntoMaximo: puntoMaximo,
            puntoMinimo: puntoMinimo,
            reporte: tiempoReporte
        };


        if (tituloWidget != "") {
            nombres.tituloWidget = tituloWidget
        }
        if (vNombreDispotivo) {
            Views.Mnombre = true
        }
        if (vIdDispositivo) {
            Views.Mdevice = true
        }
        nombres.viwes = Views
        let newWidget = {
            w: 8,
            h: 3,
            x: maxX,
            y: maxY,
            i: cantidad,
            moved: false,
            static: false,
        };
        data.posiciones.push(newWidget);
        data.identificadores.push(nombres);
        saveData(data)

    };


    const VerificarTituloWidget = (e) => {
        const inputValue = e.target.value;
        setTituloWidget(inputValue)
    }

    const handleChange = (event) => {
        setVNombreDispotivo(event.target.checked);
    };
    const handleChangeD = (event) => {
        setVIdDispositivo(event.target.checked);
    };
    
    const handleInputChanged = (event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9\b]+$/;

        if (inputValue === '' || regex.test(inputValue)) {
            setPuntoMaximo(inputValue);
        }
    };
    const handleInputChangedd = (event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9\b]+$/;

        if (inputValue === '' || regex.test(inputValue)) {
            setPuntoMinimo(inputValue);
        }
    };

    const handleInputChangeddd = (event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9\b]+$/;

        if (inputValue === '' || regex.test(inputValue)) {
            setTiempoReporte(inputValue);
        }
    };

    const enviarEliminar = () => {
        funcionEliminar()
    }

    const submitForm = () => {        
        if (data.identificadores[valorActual.index].tituloWidget != tituloWidget) {
            data.identificadores[valorActual.index].tituloWidget = tituloWidget
        }
        if (data.identificadores[valorActual.index].viwes.Mnombre != vNombreDispotivo) {
            data.identificadores[valorActual.index].viwes.Mnombre = vNombreDispotivo
        }
        if (data.identificadores[valorActual.index].viwes.Mdevice != vIdDispositivo) {
            data.identificadores[valorActual.index].viwes.Mdevice = vIdDispositivo
        }
        if (data.identificadores[valorActual.index].puntoMaximo != puntoMaximo) {
            data.identificadores[valorActual.index].puntoMaximo = puntoMaximo
        }
        if (data.identificadores[valorActual.index].puntoMinimo != puntoMinimo) {
            data.identificadores[valorActual.index].puntoMinimo = puntoMinimo
        }
        if (data.identificadores[valorActual.index].reporte != tiempoReporte) {
            data.identificadores[valorActual.index].reporte = tiempoReporte
        }        
        if (data.identificadores[valorActual.index].background!= backgroundColor) {
            data.identificadores[valorActual.index].background = backgroundColor.hex
        }
        
        Api.actualizarWidget(data).then(
            result => {
                saveData()
            }, error => {
                alert(error)
            }
        )
    }
    
    const decimalToHex = (alpha) =>
    alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

    const hexColor = useMemo(() => {
        if (typeof backgroundColor === 'string') {
            return backgroundColor;
        }
        return `${backgroundColor?.hex}${decimalToHex(backgroundColor?.rgb?.a)}`;
    }, [backgroundColor]);


    return (
        <>
            <Main>
                <><div><h5>Dispostivo : {valorActual.widget.nombre}</h5></div>
                <div><h5>ID : {valorActual.widget.device}</h5></div>
                <div><h5>Variable : {valorActual.widget.variable}</h5></div></>
                <ContenedorTitulo>
                    Parámetros
                </ContenedorTitulo>
                <TextField
                    label="Titulo Widget"
                    value={tituloWidget}
                    onChange={VerificarTituloWidget}
                />
                <Opciones>
                    <FormControlLabel
                        control={
                            <Checkbox checked={vNombreDispotivo}
                                onChange={handleChange}
                                name="Nombre del Dispositivo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="Nombre del Dispositivo"
                    /><Informa
                        info="Si desea que el nombre del dispositivo aparezca en el widget, marque la casilla."
                    />
                </Opciones>
                <Opciones>

                    <FormControlLabel
                        control={
                            <Checkbox checked={vIdDispositivo}
                                onChange={handleChangeD}
                                name="ID Disposiivo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                        }
                        label="ID Dispositivo"
                    />
                    <Informa
                        info="Si desea que el ID del dispositivo aparezca en el widget, marque la casilla."
                    />
                </Opciones>
                <Opciones>
                    <TextField
                        label="Punto Maximo."
                        value={puntoMaximo}
                        onChange={handleInputChanged}
                    />
                    <Informa
                        info="Seran los valores máximos tomados para graficar el widget."
                    />
                </Opciones>
                <Opciones>
                    <TextField
                        label="Punto Minimo."
                        value={puntoMinimo}
                        onChange={handleInputChangedd}
                    />
                </Opciones>
                <Opciones>
                    <TextField
                        label="Tiempo de maximo de reporte."
                        value={tiempoReporte}
                        onChange={handleInputChangeddd}
                        helperText="Minutos"
                    /><Informa
                        info="Si dentro del tiempo establecito, el dispositivo no reporte. No saldra datos."
                    />
                </Opciones>
                 
                <Opciones>
                    
                    <Picker color={hexColor} onChange={setBackgroudColor} />
                </Opciones>
                
                {children}
                <ContenedorBotones>
                    <ContenedorBotoness>
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" onClick={() => submitForm()}>
                                Actualizar
                            </Button>
                            <Button variant="outlined" color="error" onClick={enviarEliminar}>
                                Eliminar
                            </Button>
                        </Stack>
                    </ContenedorBotoness>
                </ContenedorBotones>

            </Main>
        </>
    )
}

export default Temperatura