import React from "react";
import Constecoin from "./Constecoin/Home";


const ViewOne = (props) => {
  if (props.name != undefined) {
    switch (props.name) {
      case "data center":
        return <Constecoin />;
      case "gpf":
        return <Constecoin />;
      case "aproca":
        return <Constecoin />;
      case "Elicrom":
        return <Constecoin />;
      case "epconst_gad_sto":
        return <Constecoin />;
      case "ecu911_sto":
        return <Constecoin />;
      default:
        return <Constecoin />;
    }
  } else {
    return <>Cargando...</>;
  }
};

export default ViewOne;
