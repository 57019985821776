import React, { useState, useEffect, useMemo } from "react";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ReplyIcon from '@material-ui/icons/Reply';
import ModalCentro from "../../components/Modal/Modal Centro";
import AUTH from "../../services/auth-service";
import API from "../../services/api-service";
import { useLocation } from "react-router-dom";
import Select1 from "../../components/Selects/Select1";
import Informa from "../../components/Informacion/index";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import Sidebar from "../../components/Dashboard/Components/Sidebar";
import TextField from "@mui/material/TextField";
import { SketchPicker as Picker } from 'react-color';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Back, Back2, BackD, BotonGuardar, CTituloDis, CTituloModal, ContenedorSe, Contenido, SelecDive, TablaDispo, Th, TituloDis, Tr, TrC, ContenedorDis, Nombredispositivo, DescripcionDispo, Organizacion, Opciones, BotonEliminar, ContenedorBotones, ContenedorBotoness } from "./DashboardElements";
import { TituloModal } from "./DashboardElements";
import TemperaturaD from "../../components/CamposWidgets/EdicionTernometro"
import Indicador from "../../components/CamposWidgets/EdicionIndicador"
import Line from "../../components/CamposWidgets/EdicionLine"
import Texto from "../../components/CamposWidgets/EdicionTexto"
import MinMax from "../../components/CamposWidgets/EdicionMinMax/index"
import Display from "../../components/CamposWidgets/Display"
import GaugeD from "../../components/CamposWidgets/EdicionGauge/index"

const decimalToHex = (alpha) =>
    alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

const Probando = ({ datas, closeM, idDashboard, funcionEliminar, funcionActualizar }) => {
    const query = new URLSearchParams(useLocation().search);
    const organizacionn = query.get("organizacion");
    const localizacionn = query.get("localizacion");
    const [showModal, setShowModal] = useState(false);
    const [showModall, setShowModall] = useState(false);
    const [Dispositivos, setDispositivos] = useState([]);
    const [value, setValue] = useState(55);
    const [valued, setValued] = useState(0);
    const [valuedd, setValuedd] = useState(30);
    const [DispositivoE, setDispositivoE] = useState("");
    const [datoTabla, setDatoTabla] = useState([]);
    const [letrasC, setLetrasC] = useState(false);
    const [validad, setValidad] = useState(false);

    const [data, setData] = useState([]);

    const [selectedDevice, setSelectedDevice] = useState(null);
    const [showVariables, setShowVariables] = useState(false);

    //Para despliqgar la variable en donde tiene que ser
    const [variableElegida, setVariableElegida] = useState({});

    const [valorWid, setValorWid] = useState(datas.widget.name);
    const [idPosicion, setIdPosicion] = useState(datas.index);

    const [tipo, setTipo] = useState(datas.widget.tipo)

    const [checked, setChecked] = useState(false);

    const [variable, setVariable] = useState("")
    const [device, setDevice] = useState("")
    const [valorT, setValorT] = useState("")

    const [color, setColor] = useState("#fafafe");
    const [reporte, setReporte] = useState("dia")
    const hexColor = useMemo(() => {
        if (typeof color === 'string') {
            return color;
        }
        return `${color?.hex}${decimalToHex(color?.rgb?.a)}`;
    }, [color]);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        closeM();
    };



    const handleShowModall = () => {
        let organizaciones = AUTH.getOrganizaciones();
        organizaciones.map((index, i) => {
            if (index.organizacion == organizacionn) {
                index.localizacion.map((localizacion, j) => {
                    if (localizacion.localiza == localizacionn) {
                        let documento = {};
                        localizacion.configuraciones.map((confi, k) => {
                            documento.label = confi.nombre;
                            documento.value = confi.catalogo;
                            Dispositivos.push(documento);
                            documento = {}
                        });
                    }
                });
            }
        });
        setShowModall(true);
    };

    const handleCloseModall = () => {
        setShowModall(false);
    };

    const saveData = () => {
        funcionActualizar();
        handleCloseModal();
        // props.cerrarModal();
        // props.funcionO(data);
        // toast.promise(
        //   API.agregarNuevoWidget(data).then((response) => {
        //     handleCloseModal();
        //     props.cerrarModal();
        //     props.funcionO(data);
        //   }),
        //   {
        //     loading: "Cargando...",
        //     success: <b>Widget Creado.!</b>,
        //     error: (err) => <b>Error al cargar datos!</b>,
        //   }
        // );
    }


    const ConsumirDispositivo = (valor) => {
        setDispositivoE(valor);
        let Data = {
            organizacion: organizacionn,
            localizacion: localizacionn,
            tipoDispositivo: valor,
        };
        toast.promise(
            API.obtenerDispositivosD(Data).then((respuesta) => {
                setDatoTabla(respuesta);
            }),
            {
                loading: "Cargando...",
                success: <b>Datos cargados!</b>,
                error: (err) => <b>Error al cargar datos!</b>,
            }
        );
    };

    function consumir() {
        setData([]);
        let data = {
            organizacion: organizacionn,
            localizacion: localizacionn,
        };
        API.getAllDBD(data).then((response) => {
            if (Object.keys(response).length > 0) {
                setData(response);
            }
        });
    }

    /*
    function consumirWidgetActualizado() {
        // setData([]);

        let data = {
          organizacion: organizacionn,
          localizacion: localizacionn,
        };
        toast.promise(
          API.getAllDas(data).then((response) => {
            if (response.error == "error") {
              setLetrasC(true)
            } else {
              setLetrasC(false)
            }
            if (Object.keys(response).length > 0) {
              setValidad(true);
              setData(response);
            }
    
          }),
          {
            loading: "Cargando...",
            success: <b>Datos cargados!</b>,
            error: (err) => <b>Error al cargar datos!</b>,
          }
        );
    }*/

    useEffect(() => {
        consumir();
    }, [datoTabla]);
    // aqui debe llegar valores como
    // se debe reenviar nombre del widgetr en la funcion de retorno

    const ProbandoIcono = (valor) => {
        if (valor != null) {
            setValorWid(valor);
            handleShowModal();
        }
    };

    function VerificarId(valor) {
        setSelectedDevice(valor);
        setShowVariables(!showVariables);
    }

    const handleVariableClick = (variable) => {
        handleCloseModall();
        const partes = variable.split("-");
        const antes = partes[0];
        const despues = partes[1];
        const nombre = partes[2];
        if (nombre == "" || nombre == undefined || nombre == null) {
            nombre = despues
        }
        setVariable(antes)
        setDevice(despues)
        let resultado = {
            deviceID: despues,
            variable: antes,
            nombre: nombre
        };
        setVariableElegida(resultado);
    };

    function RenderOpciones({ handleShowModall, variableElegida }) {
        return (
            <>
                <Opciones>
                    <Button variant="contained" onClick={handleShowModall}>
                        Seleccionar variable
                    </Button>
                </Opciones>
                {Object.keys(variableElegida).length !== 0 ? (
                    <h5>
                        Selecciono el dispositivo con la variable ({variableElegida.variable}) del dispositivo ({variableElegida.nombre}) con ID ({variableElegida.deviceID})
                    </h5>
                ) : null}
            </>
        );
    }

    function EliminarDshboard() {
        let Data = {
            "localizacion": localizacionn,
            "organizacion": organizacionn,
            "idDasbBoard": idDashboard,
            "posicion": idPosicion
        }
        funcionEliminar(Data)
        // toast.promise(
        //     API.eliminarWidget(Data).then((respuesta) => {
        //         setDatoTabla(respuesta);
        //     }),
        //     {
        //         loading: "Cargando...",
        //         success: <b>Datos cargados!</b>,
        //         error: (err) => <b>Error al cargar datos!</b>,
        //     }
        // );
    }

    return (
        <>
            <ModalCentro isOpen={showModall} onClose={handleCloseModall}>

                <h4>Seleccione una variable</h4>
                <Select1
                    message="Dispositivos"
                    value={DispositivoE}
                    setFunction={ConsumirDispositivo}
                    data={Dispositivos}
                    id="outlined-disabled"
                />

                <TablaDispo>
                    <Tr>
                        <Th>Dispositivo</Th>
                        <Th>Ultimo dato</Th>
                    </Tr>



                    {datoTabla.length > 0 ? (
                        datoTabla.map((dato, index) => (
                            <>
                                {dato.ultimoReporte != "Nunca a Reportado" ? <TrC>
                                    <td>
                                        <ContenedorDis>
                                            <Nombredispositivo key={index} onClick={() => VerificarId(dato.deviceID)}> {dato.name}</Nombredispositivo>
                                            <DescripcionDispo>{dato.deviceID}</DescripcionDispo>
                                        </ContenedorDis></td>
                                    <td>
                                        <Organizacion>{dato.ultimoReporte}</Organizacion>
                                    </td>
                                </TrC> : null}
                                {showVariables &&
                                    selectedDevice &&
                                    selectedDevice === dato.deviceID && (
                                        <>
                                            <div>
                                                {dato.variables.map((variable, index) => (
                                                    <p
                                                        key={index}
                                                        onClick={() =>
                                                            handleVariableClick(variable + "-" + dato.deviceID + "-" + dato.name)
                                                        }
                                                    ><ArrowRightIcon />
                                                        {variable}
                                                    </p>
                                                ))}
                                            </div>
                                        </>
                                    )}

                            </>
                        ))


                    ) : (
                        <p>No hay datos</p>
                    )}
                </TablaDispo>
            </ModalCentro>

            <CTituloModal>
                <TituloModal>{valorWid}</TituloModal>
            </CTituloModal>

            <ContenedorSe>
                <Back onClick={handleCloseModal}><ReplyIcon /><BackD>Regresar</BackD></Back>
                {Object.keys(variableElegida).length !== 0 ? (
                    <>
                        <h5>Selecciono el dispositivo con la variable ({variableElegida.variable}) del dispositivo ({variableElegida.nombre}) con ID ({variableElegida.deviceID}) </h5>
                    </>
                ) : null}
                <Contenido>
                    {datas.widget.hasOwnProperty('tipo') ? (
                        valorWid == "LineChart" && tipo == "test" ? (
                            <MinMax
                                data={data}
                                valorWid={valorWid}
                                device={device}
                                variable={variable}
                                DispositivoE={DispositivoE}
                                variableElegida={variableElegida}
                                saveData={saveData}
                                valorActual={datas}
                                funcionE={EliminarDshboard}
                            >
                                <RenderOpciones handleShowModall={handleShowModal} variableElegida={variableElegida} />
                            </MinMax>
                        ) : null
                    ) : (
                        valorWid == "Temperatura" ? (
                            <TemperaturaD
                                data={data}
                                valorWid={valorWid}
                                device={device}
                                variable={variable}
                                DispositivoE={DispositivoE}
                                saveData={saveData}
                                valorActual={datas}
                                funcionEliminar={EliminarDshboard}
                            >
                                <RenderOpciones handleShowModall={handleShowModall} variableElegida={variableElegida} />
                            </TemperaturaD>
                        ) : valorWid == "Humedad" || valorWid == "Batería" || valorWid == "Entrada" ? (
                            <Indicador
                                data={data}
                                valorWid={valorWid}
                                device={device}
                                variable={variable}
                                DispositivoE={DispositivoE}
                                saveData={saveData}
                                valorActual={datas}
                                funcionEliminar={EliminarDshboard}
                            >
                                <RenderOpciones handleShowModall={handleShowModall} variableElegida={variableElegida} />
                            </Indicador>
                        ) :
                            valorWid == "LineChart" || valorWid == "Metrica" ? (
                                <Line
                                    data={data}
                                    valorWid={valorWid}
                                    device={device}
                                    variable={variable}
                                    DispositivoE={DispositivoE}
                                    saveData={saveData}
                                    valorActual={datas}
                                    funcionE={EliminarDshboard}

                                    //testing
                                    setVariable={setVariable}
                                    setDevice={setDevice}
                                    Dispositivos={Dispositivos}
                                    datoTabla={datoTabla}
                                    VerificarId={VerificarId}
                                    showVariables={showVariables}
                                    selectedDevice={selectedDevice}
                                    organizacionn={organizacionn}
                                    localizacionn={localizacionn}
                                    ConsumirDispositivo={ConsumirDispositivo}
                                //termina testing
                                >
                                    {/* <RenderOpciones handleShowModall={handleShowModall} variableElegida={variableElegida} /> */}
                                </Line>
                            ) : valorWid == "Texto" ? (
                                <Texto
                                    data={data}
                                    saveData={saveData}
                                    valorActual={datas}
                                    funcionEliminar={EliminarDshboard}
                                >
                                    <RenderOpciones handleShowModall={handleShowModall} />
                                </Texto>
                            ) : valorWid == "Gauge" ? (
                                <GaugeD
                                    data={data}
                                    valorWid={valorWid}
                                    device={device}
                                    variable={variable}
                                    DispositivoE={DispositivoE}
                                    saveData={saveData}
                                    valorActual={datas}
                                    funcionEliminar={EliminarDshboard}
                                >
                                    <RenderOpciones handleShowModall={handleShowModall} variableElegida={variableElegida} />
                                </GaugeD>
                            ) : null
                    )}
                </Contenido>
            </ContenedorSe>
        </>
    );
};


export default Probando;
