import React, { useState, useEffect } from "react";
import "./TablePagination.css";
import XLSX from "xlsx";
import { Button, Card, Row, Col } from "react-bootstrap";
import { ContenedorDatos } from "../ElementosStyles";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AUTH from "../../../services/auth-service"
AUTH.setPaginaProductos()
AUTH.setPaginaProcesos()
AUTH.setCurrentProcesos()
AUTH.setPCurrentProcesos()

const TablePagination = ({
  data,
  itemsPerPageOptions,
  columnValues,
  setShowEditPro,
  deleteProducto,
}) => {
  const location = useLocation();
  const ruta = location.pathname
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [orderBy, setOrderBy] = useState({ column: "", orderType: "" });

  const history = useHistory();

  useEffect(() => {
    if (ruta === "/admin/procesos") {
      let configuracion = AUTH.getPaginaProcesos()
      let configuracionP = AUTH.getPCurrentProcesos()
      if (configuracion == null) {
        setItemsPerPage(itemsPerPageOptions[0])
      } else {
        setItemsPerPage(configuracion)
      }

      if (configuracionP == null) {
        setCurrentPage(1)
      } else {
        setCurrentPage(configuracionP)
      }

      // setCurrentPage(configuracion)
    } else {
      let configuracion = AUTH.getPaginaProdcutos()
      let configuracionP = AUTH.getCurrentProcesos()
      if (configuracion == null) {
        setItemsPerPage(itemsPerPageOptions[0])
      } else {
        setItemsPerPage(configuracion)
      }
      if (configuracionP == null) {
        setCurrentPage(1)
      } else {
        setCurrentPage(configuracionP)
      }
      // setCurrentPage(configuracion)
    }
  }, [ruta]);

  const setCurrent = (value) => {

    if (ruta === "/admin/procesos") {
      AUTH.setCurrentProcesos(value)
    } else {
      AUTH.setPCurrentProcesos(value)
    }
    setCurrentPage(value)
  }

  const handleItemsPerPageChange = (event) => {
    const perPage = parseInt(event.target.value);

    if (ruta === "/admin/procesos") {
      AUTH.setPaginaProcesos(perPage)
    } else {
      AUTH.setPaginaProductos(perPage)
    }
    setItemsPerPage(perPage);
    setCurrentPage(1);
  };

  const handleExportExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tabla");
    XLSX.writeFile(workbook, "tabla.xlsx");
  };

  const handleSort = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prevOrderBy) => ({
        column: prevOrderBy.column,
        orderType: prevOrderBy.orderType === "asc" ? "desc" : "asc",
      }));
    } else {
      setOrderBy({ column: column, orderType: "asc" });
    }
  };

  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          {columnValues.map((columnValue) => (
            <th
              key={columnValue.nValor}
              onClick={() => handleSort(columnValue.nValor)}
              className={orderBy.column === columnValue.nValor ? "sorted" : ""}
            >
              {columnValue.nColumna}{" "}
              {orderBy.column === columnValue.nValor &&
                (orderBy.orderType === "asc" ? "▲" : "▼")}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const sortedData = data.sort((a, b) => {
    const column = orderBy.column;
    const orderType = orderBy.orderType === "asc" ? 1 : -1;

    if (a[column] < b[column]) {
      return -1 * orderType;
    }
    if (a[column] > b[column]) {
      return 1 * orderType;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const renderPaginationNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage, endPage;
    if (totalPages <= maxVisiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxVisiblePagesBeforeCurrent = Math.floor(maxVisiblePages / 2);
      const maxVisiblePagesAfterCurrent = Math.ceil(maxVisiblePages / 2) - 1;

      if (currentPage <= maxVisiblePagesBeforeCurrent) {
        startPage = 1;
        endPage = maxVisiblePages;
      } else if (currentPage + maxVisiblePagesAfterCurrent >= totalPages) {
        startPage = totalPages - maxVisiblePages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxVisiblePagesBeforeCurrent;
        endPage = currentPage + maxVisiblePagesAfterCurrent;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <span
              onClick={() => setCurrentPage(1)}
              className="pagination-number"
            >
              1
            </span>
            {startPage > 2 && <span className="ellipsis">..</span>}
          </>
        )}
        {pageNumbers.map((number) => (
          <span
            key={number}
            onClick={() => setCurrent(number)}
            className={`pagination-number ${currentPage === number ? "active" : ""
              }`}
          >
            {number}
          </span>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="ellipsis">..</span>}
            <span
              onClick={() => setCurrentPage(totalPages)}
              className="pagination-number"
            >
              {totalPages}
            </span>
          </>
        )}
      </>
    );
  };

  const irProceso = (idProceso) => {
    history.push(`/admin/detalleProceso?deviceID=${idProceso}`);
  };

  const showModalElinar = () => { };

  const clicActualizarPro = (item) => {
    setShowEditPro(item);
  };
  return (
    <div className="table-container-fav">
      <div className="table-responsive-fav">
        <table className="table-fav">
          {renderTableHeader()}
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                {columnValues.map((columnValue) => {
                  if (columnValue.nValor === "verProceso") {
                    return (
                      <td>
                        <Button onClick={(e) => irProceso(item._id)}>
                          Ver proceso
                        </Button>
                      </td>
                    );
                  } else if (columnValue.nValor === "fechaInicial") {
                    const splitDateTime = item.fechaInicial.split("T");
                    const dateParts = splitDateTime[0].split("-");
                    const timeParts = splitDateTime[1].split(":");

                    const año = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                    const hora = `${timeParts[0]}:${timeParts[1]}`;
                    return (
                      <td>
                        {año} {hora}
                      </td>
                    );
                  } else if (columnValue.nValor === "lugarFinalizacion") {
                    return item.lugarFinalizacion === "Desconocido" ? (
                      <td></td>
                    ) : (
                      <td>{item.lugarFinalizacion}</td>
                    );
                  } else if (columnValue.nValor === "fechaFinalizacion") {
                    return item.lugarFinalizacion === "Desconocido" ? (
                      <td></td>
                    ) : (
                      <td>
                        {item.fechaFinalizacion
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")}{" "}
                        {item.fechaFinalizacion.split("T")[1].split(":")[0]}:
                        {item.fechaFinalizacion.split("T")[1].split(":")[1]}
                      </td>
                    );
                  } else if (columnValue.nValor === "duracionLectura") {
                    return <td>{item.duracionValor}</td>;
                  } else if (columnValue.nValor === "ultimaActualizacion") {
                    const splitDateTime = item.ultimaActualizacion.split("T");
                    const dateParts = splitDateTime[0].split("-");
                    const timeParts = splitDateTime[1].split(":");

                    const año = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                    const hora = `${timeParts[0]}:${timeParts[1]}`;
                    return (
                      <td>
                        {año} {hora}
                      </td>
                    );
                  } else if (columnValue.nValor === "accionesProductos") {
                    return (
                      <td>
                        <Button
                          onClick={() => clicActualizarPro(item)}
                          style={{ marginRight: "15px" }}
                        >
                          Editar
                        </Button>
                        <Button
                          onClick={(event) => deleteProducto(item)(event)}
                          variant="danger"
                        >
                          Eliminar
                        </Button>
                      </td>
                    );
                  } else {
                    return (
                      <td key={columnValue.nValor}>{item[columnValue.nValor]}</td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-fav">
        <div className="items-per-page">
          <label>Ver por página:</label>
          <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
            {itemsPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="pagination-numbers">{renderPaginationNumbers()}</div>
      </div>
      <ContenedorDatos>
        <Button className="buttonGeneral" onClick={handleExportExcel}>
          Exportar a Excel
        </Button>
      </ContenedorDatos>
    </div>
  );
};

export default TablePagination;
