
import React from 'react';
import "./styles.css"

const TemperaturaIndicador = (data) => {
  const min1 = data.data[0].rango.primerNivel.valor1;
  const max1 = data.data[0].rango.primerNivel.valor2;
  const min2 = data.data[0].rango.primerNivel.valor2;
  const max2 = data.data[0].rango.segundoNivel.valor2;
  const min3 = data.data[0].rango.segundoNivel.valor2;
  const max3 = data.data[0].rango.tercerNivel.valor2;

  const rangosTemperatura = [
    { min: min1, max: max1, startColor: '#0000FF', endColor: '#00FFFF' },
    { min: min2, max: max2, startColor: '#FFFF00', endColor: '#00FF00' },
    { min: min3, max: max3, startColor: '#FFA500', endColor: '#FF0000' },
    { min: Number.MIN_SAFE_INTEGER, max: min1, startColor: '#800080', endColor: '#800080' }, // Menor a min1
    { min: max3, max: Number.MAX_SAFE_INTEGER, startColor: '#800080', endColor: '#800080' }, // Mayor a max3
  ];

  const getGradientStyle = (startColor, endColor) => ({
    background: `linear-gradient(to right, ${startColor}, ${endColor})`,
    width: '100px',
    height: '20px',
  });

  return (
    <>
      <table className="temperature-table">
        <thead>
          <tr>
            <th>Rango de temperatura</th>
            <th>Color</th>
          </tr>
        </thead>
        <tbody>
          {rangosTemperatura.map((rango, index) => {
            const { min, max, startColor, endColor } = rango;
            const colorGradationStyle = getGradientStyle(startColor, endColor);

            let rangoText;

            if (min === Number.MIN_SAFE_INTEGER && max === min1) {
              rangoText = `< ${min1} °C y > ${max3} °C`;
            } else if (min === max3 && max === Number.MAX_SAFE_INTEGER) {
              rangoText = ''; 
            } else {
              rangoText = `${min} °C a ${max} °C`;
            }

            return (
              rangoText &&
              <tr key={index}>
                <td>{rangoText}</td>
                <td>
                  <div className="color-gradation" style={colorGradationStyle}></div>
                </td>
              </tr>
            );
          })}
          <td>No ha reportado</td>
          <td>
            <div className='color-gradation' style={{ background: '#808080', opacity:'0.4' }}></div>
          </td>
        </tbody>
      </table>
    </>
  );
};

export default TemperaturaIndicador;
