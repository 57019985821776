import React, { useState, useMemo } from 'react';
import TextField from "@mui/material/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Informa from "../../Informacion/index";
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AUTH from "../../../services/auth-service";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { SketchPicker as Picker } from 'react-color';
import ModalCentro from "../../../components/Modal/Modal Centro";
import Select1 from "../../../components/Selects/Select1";
import Swal from "sweetalert2";
import { Back, BackD, CTituloModal, ContenedorSe, Contenido, TablaDispo, Th, Tr, TrC, ContenedorDis, Nombredispositivo, DescripcionDispo, Organizacion } from "../../../views/Dashboard/DashboardElements";
import { ContenedorTitulo, Main, Opciones, BotonGuardar } from '../Elements';


const Line = ({ data, valorWid, device, variable, variableElegida, DispositivoE, saveData,
    setVariable, setDevice, setVariableElegida, ConsumirDispositivo,
    datoTabla, VerificarId, showVariables, selectedDevice, organizacionn, localizacionn }) => {


    const [tituloWidget, setTituloWidget] = useState("");
    const [tiempoReporte, setTiempoReporte] = useState(1440);
    const [backgroundColor, setBackgroudColor] = useState("#ffffff");
    const [checkColor, setColor] = useState(false);
    const [iterador, setIterador] = useState(null);
    const [variablesSeleccionadas, setVariablesSeleccionadas] = useState([]);
    const [variableS, setVariables] = useState([]);
    const [tipoDispositivo, setTipoDispositivo] = ([]);
    const [Dispositivos, setDispositivos] = useState([]);
    const [listDispositivos, setListaDispositivos] = useState([]);
    const [showModall, setShowModall] = useState(false);

    const [funcionEjecutada, setFuncionEjecutada] = useState(true);  

    const handleMostrarComponente = (index) => {             
        let organizaciones = AUTH.getOrganizaciones();
        organizaciones.map((index, i) => {
            if (index.organizacion == organizacionn) {
                index.localizacion.map((localizacion, j) => {
                    if (localizacion.localiza == localizacionn) {
                        let documento = {};
                        localizacion.configuraciones.map((confi, k) => {
                            documento.label = confi.nombre;
                            documento.value = confi.catalogo;
                            Dispositivos.push(documento);
                            documento = {}
                        });
                    }
                });
            }
        });        
        setShowModall(true)        
        setIterador(index)
    };

    const GuardarData = () => {
        var Mdevice = variablesSeleccionadas.map(function(subarreglo){            
            return subarreglo[1]            
        })
        var Mnombre = variablesSeleccionadas.map(function(subarreglo){            
            return subarreglo[2]
        })
        var devicesValidos = variableS.filter(function(objeto) {
            return objeto.deviceID !== undefined;
        });
        var devices = devicesValidos.map(function(objeto){
            return objeto.deviceID
        })
        var variablesValidos = variableS.filter(function(objeto) {
            return objeto.deviceID !== undefined;
        });
        var avariables = variablesValidos.map(function(objeto){
            return objeto.variable
        })
        var names = variableS.map(function(objeto){
            return objeto.nombre
        })

        let Views = {
            "Mdevice": Mdevice,
            "Mnombre": Mnombre,
        }
        let maxY
        let cantidad
        let maxX
        if (Object.keys(data).length == 4) {
            maxY = 0
            cantidad = 1
            data.posiciones = []
            data.identificadores = []
        } else {
            maxY = data.posiciones[0].y;
            maxX = data.posiciones[0].x;
            cantidad = data.posiciones.length + 1;
            cantidad = cantidad.toString();

            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y > maxY) {
                    maxY = data.posiciones[i].y;
                }
            }
            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y == maxY) {
                    if (data.posiciones[i].x > maxX) {
                        maxX = data.posiciones[i].x;
                    }
                }
            }
        }

        if (maxX > 4) {
            maxX = 0
            maxY = maxY + 1
        } else {
            maxX = maxX + 1
        }

        let nombres = {
            name: valorWid,
            device: devices,
            variable: avariables,
            typeDevice: listDispositivos,
            nombre: names,
            reporte: Number(tiempoReporte),
            background: hexColor
        };


        if (tituloWidget != "") {
            nombres.tituloWidget = tituloWidget
        }
        nombres.viwes = Views
        let newWidget = {
            w: 11,
            h: 2,
            x: maxX,
            y: maxY,
            i: cantidad,
            moved: false,
            static: false,
        };
        data.posiciones.push(newWidget);
        data.identificadores.push(nombres);
        saveData(data)
    };

    const VerificarTituloWidget = (e) => {
        const inputValue = e.target.value;
        setTituloWidget(inputValue);
    };    

    const handleChangeC = (event) => {
        setColor(event.target.checked);
    };


    const handleCloseModall = () => {
        setShowModall(false);
        setDispositivos([])
    };
    const decimalToHex = (alpha) =>
        alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

    const hexColor = useMemo(() => {
        if (typeof backgroundColor === 'string') {
            return backgroundColor;
        }
        return `${backgroundColor?.hex}${decimalToHex(backgroundColor?.rgb?.a)}`;
    }, [backgroundColor]);

    const agregarVariables = () => {
        if(!funcionEjecutada){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Selecciona una variable primero'                
              })    
        }else{
            setVariablesSeleccionadas([...variablesSeleccionadas, [true, true]]);
            setFuncionEjecutada(false)
            variableS.push({})
        }  
    };

    const eliminarVariable = (index) => {
        const nuevasVariablesSeleccionadas = [...variablesSeleccionadas];
        nuevasVariablesSeleccionadas.splice(index, 1);
        if (data.identificadores.length > index) {
            data.identificadores.splice(index, 1);
        }
        setVariablesSeleccionadas(nuevasVariablesSeleccionadas);

        const nuevasVarialeS = [...variableS];
        nuevasVarialeS.splice(index, 1);
        setVariables(nuevasVarialeS)

        const nuevosDispositivos = [...listDispositivos];
        nuevosDispositivos.splice(index,1);
        setListaDispositivos(nuevosDispositivos)
        setFuncionEjecutada(true)
    }

    const handleVariableClick = (variable) => {
        if(variablesSeleccionadas[iterador].length == 3){
            variablesSeleccionadas[iterador].splice(0, 1, variable)
        }else{
            variablesSeleccionadas[iterador].splice(0, 0, variable)
        }       
        handleCloseModall();
        const partes = variable.split("-");
        const antes = partes[0];
        const despues = partes[1];
        const nombre = partes[2];
        if (nombre == "" || nombre == undefined || nombre == null) {
            nombre = despues
        }
        setVariable(antes)
        setDevice(despues)
        let resultado = {
            deviceID: despues,
            variable: antes,
            nombre: nombre
        };
        setVariableElegida(resultado);     
        // variableS.pop()
        // variableS.push(resultado);
        
        variableS.splice(iterador, 1 ,resultado)
        setFuncionEjecutada(true)
        setDispositivos([])
        
        setListaDispositivos(prevListDispositivos => [...prevListDispositivos, DispositivoE]);     
    };

    return (
        <>

            <Main>
                <ContenedorTitulo>
                    Parámetros
                </ContenedorTitulo>
                <TextField
                    label="Titulo Widget"
                    value={tituloWidget}
                    onChange={VerificarTituloWidget}
                />
                <Opciones>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Tiempo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={tiempoReporte}
                            label="Estado"
                            onChange={(e) => setTiempoReporte(e.target.value)}
                        >
                            <MenuItem value="1440">ultimas 24 horas</MenuItem>
                            <MenuItem value="10080">ultima semana</MenuItem>
                            <MenuItem value="43800">ultimo mes</MenuItem>
                            <MenuItem value="131400">ultimos tres meses</MenuItem>
                            <MenuItem value="525600">ultimo año</MenuItem>
                        </Select>
                    </FormControl>
                </Opciones>
                <Opciones>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkColor}
                                onChange={handleChangeC}
                                name="Color de fondo"
                                inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                            />
                        }
                        label="Color de fondo"
                    /><Informa
                        info="Si desea cambiar el color de fondo del widget marque esta casilla"
                    />
                </Opciones>
                {checkColor !== false ? (
                    <Opciones>
                        <Picker color={hexColor} onChange={setBackgroudColor} />
                    </Opciones>
                ) : null}
                <Opciones>
                    <Button variant="contained" onClick={agregarVariables}>
                        Agregar variable
                    </Button>
                </Opciones>


                {variablesSeleccionadas.map((variables, index) => (
                    <div key={index}>                        
                        <Opciones>
                            <Button variant="contained" onClick={() => handleMostrarComponente(index)}>
                                Seleccionar variable
                            </Button>
                        </Opciones>
                        <Opciones>
                            <Button variant="outlined" color="error" onClick={() => eliminarVariable(index)}>
                                Eliminar Variable
                            </Button>
                        </Opciones>
                        {Object.keys(variableS).length  > 0 ? (
                            <>
                                <h5>Seleccionó el dispositivo con la variable ({variableS[index].variable}) del dispositivo ({variableS[index].nombre}) con ID ({variableS[index].deviceID}) </h5> 
                            </>
                        ) : null}
                        {index < variablesSeleccionadas.length - 1 && <hr />}
                    </div>
                ))}

                <ModalCentro isOpen={showModall} onClose={handleCloseModall}>

                    <h4>Seleccione una variable</h4>
                    <Button style={{ position: "absolute", top: "10px", right: "10px" }} variant="contained" color="primary" onClick={handleCloseModall}>
                        X
                    </Button>
                    <Select1
                        message="Dispositivos"
                        value={DispositivoE}
                        setFunction={ConsumirDispositivo}
                        data={Dispositivos}
                        id="outlined-disabled"
                    />

                    <TablaDispo>
                        <Tr>
                            <Th>Dispositivo</Th>
                            <Th>Ultimo dato</Th>
                        </Tr>

                        {datoTabla.length > 0 ? (
                            datoTabla.map((dato, index) => (
                                <>
                                    {dato.ultimoReporte != "Nunca a Reportado" ? <TrC>
                                        <td>
                                            <ContenedorDis>
                                                <Nombredispositivo key={index} onClick={() => VerificarId(dato.deviceID)}> {dato.name}</Nombredispositivo>
                                                <DescripcionDispo>{dato.deviceID}</DescripcionDispo>
                                            </ContenedorDis></td>
                                        <td>
                                            <Organizacion>{dato.ultimoReporte}</Organizacion>
                                        </td>
                                    </TrC> : null}
                                    {showVariables &&
                                        selectedDevice &&
                                        selectedDevice === dato.deviceID && (
                                            <>
                                                <div>
                                                    {dato.variables.map((variable, index) => (
                                                        <p
                                                            key={index}
                                                            onClick={() =>
                                                                handleVariableClick(variable + "-" + dato.deviceID + "-" + dato.name)
                                                            }
                                                        ><ArrowRightIcon />
                                                            {variable}
                                                        </p>
                                                    ))}
                                                </div>
                                            </>
                                        )}

                                </>
                            ))
                        ) : (
                            <p>No hay datos</p>
                        )}
                    </TablaDispo>
                </ModalCentro>

                {device !== null && device !== "" ? <BotonGuardar onClick={GuardarData}>Guardar</BotonGuardar> : null}
            </Main>
        </>
    );
}

export default Line;
