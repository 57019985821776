import styled from "styled-components";


export const Main = styled.div`
  background-color: ${props => props.background};
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  width: 100%;
`;

export const Contenedor = styled.div`
  ${'' /* margin: 50px; */}
  ${'' /* background-color: red; */}
  ${'' /* margin: 16px; */}
  height: 100%;
  width: 100%;
`;
export const Testing = styled.div`
  background-color: ${props => props.background};
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  width: 100%;
`;

export const Titulo = styled.div`
  text-align: center;
  font-weight: bold;
  
`;

export const Actalizacion = styled.div`
  font-size: 12px;
  padding: 10px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
`;


export const ContenedorThermometer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%; /* La imagen no se ampliará más allá de su tamaño original */
  max-height: 100%; /* La imagen no se ampliará más allá de su tamaño original */
  display: flex; 
  flex-direction: column; /* Alinear elementos internos verticalmente */

`;

export const NombreD = styled.div`
  font-size: 15px;
  padding: 10px 10px;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const IndicadorT = styled.div`
  padding: 14px 0px;
  float: right;
  font-size: 12px;
`;
export const NombreR = styled.div`
  position: absolute;
  left: 0;
  padding-left: 10px;
`;

export const ImagenWid = styled.img`
  max-width: 50%;
  max-height: 10%;
  ${'' /* display: inline-block; */}
 ${'' /* width: 60px;
 height: 60px; */}
`;


export const ContenedorWa = styled.div`
  display: flex;
  // background: red;
  justify-content: center;
  align-items: center;
`;


export const IconoEdit = styled.div`
text-align: right;
`;

