import React, { Component } from "react";
import LiquidFillGauge from "react-liquid-gauge";

class TankLevel extends Component {
  state = {
    value: 50,
  };

  render() {
    const radius = 80;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <LiquidFillGauge
          style={{ margin: "auto" }}
          width={radius * 2}
          height={radius * 2}
          value={this.state.value}
          percent="%"
          textSize={1}
          textOffsetX={0}
          textOffsetY={0}
          textRenderer={(props) => {
            const value = Math.round(props.value);
            const radius = Math.min(props.height / 2, props.width / 2);
            const textPixels = (props.textSize * radius) / 2;
            const valueStyle = {
              fontSize: textPixels,
            };
            const percentStyle = {
              fontSize: textPixels * 0.6,
            };

            return (
              <tspan>
                <tspan className="value" style={valueStyle}>
                  {value}
                </tspan>
                <tspan style={percentStyle}>{props.percent}</tspan>
              </tspan>
              // riseAnimation
            // waveAnimation
            // waveFrequency={2}
            // waveAmplitude={1}
            // gradient
            // gradientStops={gradientStops}
            // circleStyle={{
            //   fill: fillColor,
            // }}
            // waveStyle={{
            //   fill: fillColor,
            // }}
            // textStyle={{
            //   fill: color("#444").toString(),
            //   fontFamily: "Arial",
            // }}
            // waveTextStyle={{
            //   fill: color("#fff").toString(),
            //   fontFamily: "Arial",
            // }}
            );
          }}
        />
      </div>
    );
  }
}

export default TankLevel;
