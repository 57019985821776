import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import API from "../../services/api-service";
import { Form } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import Tabla from "./Tabla/index";
import { Main, ContenedorTitulo, ContenedorFechas } from "./ElementosStyles";

const Index = () => {
    const itemsPerPageOptions = [50, 100, 200];
    const query = new URLSearchParams(useLocation().search);
    const organizacionn = query.get("organizacion");
    const localizacionn = query.get("localizacion");
    const [dispositivoLoca1, setDispositivoLoca1] = useState("Todos");
    const [dispositivoLoca2, setDispositivoLoca2] = useState("Todos");
    const [dispositivosLoca1, setDispositivosLoca1] = useState([]);
    const [dispositivosLoca2, setDispositivosLoca2] = useState([]);
    const [estadoLoca1, setEstadoLoca1] = useState("all");
    const [columnValues, setColumValues] = useState([]);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const idAlerta = "64356e5890aefd977d573419";

    useEffect(() => {
        const fetchDispositivos = async () => {
            let dispositivosResponse = [];
            let columnas = [];

            if (organizacionn === "gpf") {
                if (localizacionn === "gpf_loca_1") {
                    dispositivosResponse = await API.obtenerDispositivosHumitemp({ organizacion: organizacionn, estado: "all" });
                    columnas = [
                        { nColumna: 'Device ID', nValor: 'deviceID' },
                        { nColumna: 'Nombre', nValor: 'nombre' },
                        { nColumna: 'Organizacion', nValor: 'organizacion' },
                        { nColumna: 'Tipo alerta', nValor: 'tipoAlerta' },
                        { nColumna: 'Fecha Alerta', nValor: 'fecha' },
                        { nColumna: 'Revisado', nValor: 'revisado' }
                    ];
                    setDispositivosLoca1(dispositivosResponse);
                } else if (localizacionn === "gpf_loca_2") {
                    dispositivosResponse = await API.obtenerDispositivosS1({ organizacion: organizacionn, tipo: "ble_1" });
                    columnas = [
                        { nColumna: 'Device ID', nValor: 'deviceID' },
                        { nColumna: 'Nombre', nValor: 'nombre' },
                        { nColumna: 'Organización', nValor: 'organizacion' },
                        { nColumna: 'Tipo alerta', nValor: 'tipo' },
                        { nColumna: 'Fecha alerta', nValor: 'fecha' }
                    ];
                    setDispositivosLoca2(dispositivosResponse);
                }
            }

            setColumValues(columnas);
        };

        const fetchData = async () => {
            const toastId = toast.loading("Cargando datos...");
            setLoading(true);

            try {
                setData([]);
                let respuesta = {};

                if (organizacionn === "gpf" && localizacionn === "gpf_loca_1") {
                    respuesta = await API.adquirirAlertasDevices({ organizacion: organizacionn, deviceID: "Todos", estado: "all" });
                } else if (organizacionn === "gpf" && localizacionn === "gpf_loca_2") {
                    respuesta = await API.getAlertOne(idAlerta, organizacionn, "all", "alerta");
                }

                if (respuesta.mensaje === "No existen documentos") {
                    toast.error("No existen datos");
                    setData([]);
                } else {
                    setData(respuesta.result || respuesta);
                }
            } catch (error) {
                console.error(error);
                toast.error("Error al cargar los datos históricos");
            } finally {
                toast.dismiss(toastId);
                setLoading(false);
            }
        };

        fetchDispositivos();
        fetchData();
    }, [organizacionn, localizacionn]);

    useEffect(() => {
        // Filtrar los datos según el dispositivo y estado seleccionado
        let newFilteredData = [...data];

        if (localizacionn === "gpf_loca_1") {
            if (dispositivoLoca1 !== "Todos") {
                newFilteredData = newFilteredData.filter(item => item.deviceID === dispositivoLoca1);
            }

            if (estadoLoca1 !== "all") {
                const isRevisado = estadoLoca1 === "true";
                newFilteredData = newFilteredData.filter(item => item.revisado === isRevisado);
            }
        } else if (localizacionn === "gpf_loca_2") {
            if (dispositivoLoca2 !== "Todos") {
                newFilteredData = newFilteredData.filter(item => item.deviceID === dispositivoLoca2);
            }
            // No se aplica el filtro de estado para gpf_loca_2
        }

        setFilteredData(newFilteredData);
    }, [data, dispositivoLoca1, estadoLoca1, dispositivoLoca2, localizacionn]);

    return (
        <Main>
            <Toaster />
            <ContenedorTitulo>Alertas dispositivos</ContenedorTitulo>
            {localizacionn === "gpf_loca_1" && (
                <>
                    <ContenedorFechas>
                        <Form.Group controlId="selectDeviceLoca1">
                            <Form.Label>Dispositivos:</Form.Label>
                            <Form.Select value={dispositivoLoca1} onChange={e => setDispositivoLoca1(e.target.value)}>
                                <option value="Todos">Todos</option>
                                {dispositivosLoca1.map((device, index) => (
                                    <option key={index} value={device.deviceID}>
                                        {device.nombre}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </ContenedorFechas>
                    <ContenedorFechas>
                        <Form.Group controlId="selectEstadoLoca1">
                            <Form.Label>Revisados:</Form.Label>
                            <Form.Select value={estadoLoca1} onChange={e => setEstadoLoca1(e.target.value)}>
                                <option value="all">TODO</option>
                                <option value="true">Revisados</option>
                                <option value="false">No revisados</option>
                            </Form.Select>
                        </Form.Group>
                    </ContenedorFechas>
                </>
            )}
            {localizacionn === "gpf_loca_2" && (
                <ContenedorFechas>
                    <Form.Group controlId="selectDeviceLoca2">
                        <Form.Label>Dispositivos:</Form.Label>
                        <Form.Select value={dispositivoLoca2} onChange={e => setDispositivoLoca2(e.target.value)}>
                            <option value="Todos">Todos</option>
                            {dispositivosLoca2.map((device, index) => (
                                <option key={index} value={device.deviceID}>
                                    {device.nombre}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </ContenedorFechas>
            )}
            {loading ? (
                <div>Cargando...</div>
            ) : filteredData.length > 0 ? (
                <Tabla data={filteredData} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnValues} />
            ) : (
                <div>No existen datos</div>
            )}
        </Main>
    );
};

export default Index;
