import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { render } from "react-dom";

import almacenajeImg from "../../../assets/img/almacenaje-01.png";
import distribucionImg from "../../../assets/img/distribucion-01.png";
import administracionImg from "../../../assets/img/administracion.png";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      devicesTable: [],
    };
  }

  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <div className="col-4">
              <h1 className="titulo-pagina">Administración</h1>
              <img src={administracionImg} className="imagenes" />
            </div>
            <div className="col-4">
              <h1 className="titulo-pagina"> Cadena en Frío</h1>
              <img src={almacenajeImg} className="imagenes" />
            </div>
            <div className="col-4">
              <h1 className="titulo-pagina">Distribución</h1>
              <img src={distribucionImg} className="imagenes" />
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Home;
