import React from "react";
import { Bar } from 'react-chartjs-2';
import { Resizable } from 'react-resizable';
import 'chartjs-adapter-moment';
import { Contenedor, Main } from '../LineChart/ElementsDisplay';

const BarChart = ({ info }) => {

    const procesarData = (info) => {
        const disconnectCounts = {};

        info.forEach(item => {
            const fecha = item.fechaDesconexion.split('T')[0];
            if (disconnectCounts[fecha]) {
                disconnectCounts[fecha] += 1;
            } else {
                disconnectCounts[fecha] = 1;
            }
        });

        return disconnectCounts;
    };

    const disconnectCounts = procesarData(info);

    const data = {
        labels: Object.keys(disconnectCounts),
        datasets: [
            {
                label: 'Número de Desconexiones',
                data: Object.values(disconnectCounts),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
            }
        ]
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'MMM D YYYY', // Formato del tooltip
                    displayFormats: {
                        day: 'D MMM' // Formato del eje X (día y mes)
                    }
                }
            },
            y: {
                beginAtZero: true
            }
        }
    };


    return (
        <>
            {info.length > 0 ? <>
                <Main>
                    <Contenedor>
                        <Resizable>
                            <Bar data={data} options={options} />
                        </Resizable>
                    </Contenedor>
                </Main>
            </> : <p>No hay Datos</p>}
        </>
    )
}

export default BarChart