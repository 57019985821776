// ** React Imports
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// ** Custom Components
// import Avatar from '../../@core/components/avatar'

// ** React Imports
import { Fragment } from 'react'
import React from "react";
// ** Custom Components
import Avatar from '../../@core/components/avatar'
import "./NotificationDropdown.css";
import { browserHistory } from 'react-router';
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Bell, X, Check, AlertTriangle } from 'react-feather'
import Api from '../../services/api-service.js'
import Auth from '../../services/auth-service.js'


import {
  Button,
  Badge,
  Media,
  CustomInput,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'

// // ** Utils
// // import { isUserLoggedIn } from '@utils'

// // ** Store & Actions
// import { useDispatch } from 'react-redux'
// // import { handleLogout } from '@store/actions/auth'

// // ** Third Party Components
// import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power } from 'react-feather'

// // ** Default Avataraaaaa
import defaultAvatar from '../../assets/img/person.png'

class DateAndHour extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fechaActual: new Date(),
    };

    // seteo de hora
    setInterval(() => {
      this.setState({ fechaActual: new Date() })
    }, 1000)

  }


  render() {
    return (
      // <div className='hora'>{this.state.fechaActual.toString().slice(0, this.state.fechaActual.toString().indexOf('GMT'))}</div>
      <div className='hora'>{this.state.fechaActual.toLocaleString('es-ES', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })}</div>
    )
  }
}

export default DateAndHour
