import styled from "styled-components";


export const Main = styled.div`
    background-color: ${props => props.background};
    border-radius: 5px;
    padding: 10px;
    height: 100%;
    width: 100%;
`;

export const Contenedor = styled.div`
  ${'' /* margin: 50px; */}
  ${'' /* background-color: red; */}
    // margin: 16px;
`;
export const Testing = styled.div`
    background-color: ${props => props.background};
    border-radius: 5px;
    padding: 10px;
    height: 100%;
  width: 100%;
`;

export const Titulo = styled.div`
  text-align: center;
  font-weight: bold;
`;

export const Actalizacion = styled.div`
  font-size: 12px;
  padding: 10px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const NombreD = styled.div`
position: absolute;
  right: 0;
  padding-right: 10px;
  ${'' /* left: 0; */}
`;

export const IconoEdit = styled.div`
  
  
`;