import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import Chart from 'chart.js/auto'; //funciona con las versiones 3.9.1 de chart.js y 5.2.0 de react-chartjs-2
import zoomPlugin from 'chartjs-plugin-zoom';
import "./style.scss"

const LineChart = ({ array }) => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);
  const [initialScales, setInitialScales] = useState(null);

  const generateRandomColor = () => {
    const getRandomValue = () => Math.floor(Math.random() * 256);
    return `rgba(${getRandomValue()}, ${getRandomValue()}, ${getRandomValue()}, 0.7)`;
  };

  const getRandomColorMap = (names) => {
    const colorMap = {};
    names.forEach(name => {
      if (!colorMap[name]) {
        colorMap[name] = generateRandomColor();
      }
    });
    return colorMap;
  };

  const seccionNames = array.saltos.map(salto => salto.nombre);

  const colorMap = getRandomColorMap(seccionNames);

  const resetZoomChart = () => {
    if (chartInstance.current && initialScales) {
      chartInstance.current.resetZoom();
      chartInstance.current.options.scales.x.min = initialScales.x.min;
      chartInstance.current.options.scales.x.max = initialScales.x.max;
      chartInstance.current.update();
    }
  };

  useEffect(() => {
    const parsedLabels = array.ejex.map(value => {
      const date = new Date(value);
      date.setHours(date.getHours() + 5);
      return format(date, 'yyyy/MM/dd HH:mm', { timeZone: 'UTC' });
    });

    

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const datas = {
      labels: [],
      datasets: []
    };


    datas.labels = parsedLabels
    const graficoPrincipal = {
      label: 'temperatura',
      data: array.ejey,
      backgroundColor: 'rgba(255, 26, 104, 0.2)',
      borderColor: 'rgba(255, 26, 104, 1)',
      tension: 0.3,
    };

    const secciones = array.saltos.map((salto) => ({
      label: salto.nombre,
      data: [
        { x: parsedLabels[salto.inicio], y: 0 },
        { x: parsedLabels[salto.final], y: 0 }
      ],
      backgroundColor: colorMap[salto.nombre],
      borderColor: colorMap[salto.nombre],
      borderWidth: 0,
      fill: 0,
      radius: 0,
    }));
    datas.datasets.push(graficoPrincipal, ...secciones);

    const config = {
      type: 'line',
      data: datas,
      options: {
        scales: {
          y: {
            beginAtZero: true
          },
          x: {
            ticks: {
              color: (context) => {
                const index = context.index;
                const values = context.chart.data.labels.length;

                if (index === 0 || index === values - 1) {
                  return '#FF4D4D';
                }

                return 'black';
              }
            }
          }
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            borderWidth: 1,
          },
        },
        plugins: {
          legend: {
            labels: {
              generateLabels: function (chart) {
                const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);

                const usedLabels = {};

                return labels.filter(label => {
                  if (usedLabels[label.text]) {
                    return false;
                  }
                  usedLabels[label.text] = true;
                  return true;
                });
              }
            },
            onClick: (event, legendItem, legend) => {
              const index = legendItem.datasetIndex;
              const ci = legend.chart;
              const meta = ci.getDatasetMeta(index);

              meta.hidden = !meta.hidden;

              // Oculta todas las áreas con el mismo nombre en el gráfico
              ci.data.datasets.forEach((dataset, i) => {
                if (dataset.label === meta.label) {
                  ci.getDatasetMeta(i).hidden = meta.hidden;
                }
              });

              ci.update();
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
              threshlod: 10,
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              mode: 'x',
              drag: {
                enabled: true,
              }
            }
          },
          tooltip: {
            intersect: false,
            callbacks: {
              title: function (context) {
                // Obtén el índice del elemento actual
                const index = context[0].dataIndex;
    
                // Encuentra la sección correspondiente según la posición en el array
                const sectionIndex = array.saltos.findIndex(
                  (salto) => index >= salto.inicio && index <= salto.final
                );
    
                // Si se encuentra la sección, devuelve el nombre
                if (sectionIndex !== -1) {
                  return array.saltos[sectionIndex].nombre;
                }
    
                // Si no se encuentra la sección, simplemente devuelve la fecha
                return context[0].label;
              },
              label: function (context) {
                // Puedes personalizar el formato de la etiqueta aquí si es necesario
                return `Temperatura: ${context.parsed.y}`;
              },
            },
          }         
        }
      },
      plugins: [zoomPlugin]
    };

    if (chartContainer.current) {
      chartInstance.current = new Chart(chartContainer.current, config);
      chartContainer.current.addEventListener('contextmenu', showContextMenu);
    }

    setInitialScales({
      x: { min: 0, max: datas.labels.length - 1 },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

 

  const showContextMenu = (event) => {
    event.preventDefault();
    const chartMenu = document.getElementById('chartMenu');
    chartMenu.style.display = 'block';
    chartMenu.style.left = `${event.pageX}px`;
    chartMenu.style.top = `${event.pageY}px`;

    document.onclick = hideContextMenu
  }

  const hideContextMenu = () => {
    if (chartContainer.current) {
      const chartMenu = document.getElementById('chartMenu');
      chartMenu.style.display = 'none';
    }
  };

  const chartZoom = (value) => {
    if (chartInstance.current) {
      const { scales } = chartInstance.current.options;
      scales.x.max += value;
      scales.x.min -= value;
      chartInstance.current.update();
      hideContextMenu();
    }

  }

  return (
    <div className="chartCard">
      <div className="chartBox">
        <canvas ref={chartContainer} id="myChart"></canvas>
      </div>
      <div id="chartMenu" className='context-menu' style={{ display: "none" }}>
        <ul>
          <li onClick={() => chartZoom(-5)}>Zoom In</li>
          <li onClick={() => chartZoom(5)}>Zoom Out</li>
          <li onClick={() => resetZoomChart()}>Zoom Reset</li>
        </ul>
      </div>
    </div>
  );
};

export default LineChart;
