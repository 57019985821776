import React from "react";
import "./style.scss";
import { Actalizacion, Contenedor, ContenedorWa, IconoEdit, ImagenWid, IndicadorT, Main, NombreD, StyledReactGridItem, Testing, Titulo } from './ElementsDisplay';
import EditIcon from '@material-ui/icons/Edit';
import Advertencia from "./warning.png";
import { Resizable } from 'react-resizable';

class Thermometer extends React.Component {
  constructor(props) {
    super(props);
    const temperaturaKey = this.props.temperaturaKey;
    const value = this.props.info[temperaturaKey] || this.props.info.temperature || this.props.info.temperatura || this.props.info.deviceTemperature || this.props.info.Tambiente || 0;

    this.state = {
      ultimoData: this.props.info.ultimoDato || this.props.info.fecha || false,
      theme: () => (this.props.theme === 'light' || this.props.theme === 'dark') ? this.props.theme : 'light',
      value: value,
      max: 100,
      steps: this.props.steps || 4,
      format: this.props.format || '°C',
      size: () => (this.props.size === 'small' || this.props.size === 'normal' || this.props.size === 'large') ? this.props.size : 'normal',
      height: this.props.height || 200,
      valstr: () => `${this.state.value} ${this.state.format}`,
      percent: () => (this.state.value / this.state.max) * 100,
      intervals: []
    };

    for (let step = 0; step <= this.state.steps; step++) {
      let val = ((this.state.max / this.state.steps) * step).toFixed(2);
      let percent = (val / this.state.max) * 100;
      let interval = { percent: percent, label: `${val}${this.state.format}` };
      this.state.intervals.push(interval);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.info !== prevProps.info) {
      const temperaturaKey = this.props.temperaturaKey;
      const value = this.props.info[temperaturaKey] || this.props.info.temperature || this.props.info.temperatura || this.props.info.deviceTemperature || this.props.info.Tambiente || 0;

      this.setState({
        ultimoData: this.props.info.ultimoDato || this.props.info.fecha || true,
        value: value,
        valstr: () => `${this.state.value} ${this.state.format}`,
        percent: () => (this.state.value / this.state.max) * 100,
      });
    }
  }

  render() {
    const theme = `thermometer--theme-${this.state.theme()}`;
    const size = `thermometer--${this.state.size()}`;
    const height = { height: `${this.state.height}px` };
    const heightPercent = { height: `${this.state.percent()}%` };
    const heightBgColor = { height: `calc(${this.state.height}px - 57px)` };
    const valstr = this.state.valstr();
    const stepIntervals = this.state.intervals.map((step, i) => {
      return (
        <li key={i} style={{ bottom: `calc(${step.percent}% - 1px)` }}>
          {step.label}
        </li>
      );
    });

    return (
      <>
        <Main background={this.state.background}>
          {this.state.ultimoData !== false ? (
            <>
              <Contenedor>
                <div style={height} className={`thermometer ${size} ${theme}`}>
                  <div className="thermometer__draw-a"></div>
                  <div className="thermometer__draw-b"></div>
                  <div className="thermometer__meter">
                    <ul className="thermometer__statistics">{stepIntervals}</ul>
                    <div style={heightPercent} className="thermometer__mercury">
                      <div className="thermometer__percent-current">{valstr}</div>
                      <div className="thermometer__mask">
                        <div className="thermometer__bg-color" style={heightBgColor}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Contenedor>

            </>
          ) : (
            <>
              <p>El dispositivo no ha reportado</p>
              <ContenedorWa>
                <ImagenWid src={Advertencia} />
              </ContenedorWa>
            </>
          )}
        </Main>
      </>
    );
  }
}

export default Thermometer;
