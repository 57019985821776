import React from "react";
import moment from "moment-timezone";
import { Line } from 'react-chartjs-2';
import { Resizable } from 'react-resizable';
import EditIcon from '@material-ui/icons/Edit';
import { Actalizacion, Contenedor, IconoEdit, IndicadorT, Main, NombreD, NombreR, StyledReactGridItem, Testing, Titulo } from './ElementsDisplay';

export default function App({ info }) {
  let options = {
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'hour',
          tooltipFormat: 'll HH:mm'
        }
      }]
    },
    maintainAspectRatio: false
  };


  // Ajustar la hora en el eje X restando 5 horas
  const adjustedEjex = info.map((fecha) => {
    const date = new Date(fecha.fechaCompleta);
    // const opciones = { timeZone: "America/Guayaquil" };
    // const fechaFormateada = date.toLocaleString('en-ES', opciones);
    date.setHours(date.getHours() + 5);
    return date;
  });
  // Ajustar la hora en el eje X restando 5 horas
  const valorHumedad = info.map((valor) => {
    return !valor.doorStatus;
  });


  const data = {
    labels: adjustedEjex,
    datasets: [
      {
        label: "Movimiento",
        data: valorHumedad,
        fill: true,
        pointRadius: 1.8,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      }
    ]
  };

  function handleMouseOver(event) {
    event.target.style.cursor = 'pointer';
  }

  return (
    <>
      {info.length > 0 ? <>

        <Main>
          <Contenedor>
            <Resizable>
              <Line data={data} options={options} />
            </Resizable>
          </Contenedor>
        </Main>
      </> : <p>No hay Datos</p>}
    </>
  );
}