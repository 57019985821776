import React, { Component } from "react";
import "../../layouts/Login.scss";
import Api from "../../services/api-service.js";
import Auth from "../../services/auth-service.js";
import "../../styles.css";

const Input = ({ id, type, label, handleChange }) => (
  <input
    className="form-group__input"
    type={type}
    id={id}
    placeholder={label}
    onChange={handleChange}
  />
);

class LoginComponent extends Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = (e) => {
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });
  };

  loginConsume = (e) => {
    e.preventDefault();
    let body = {
      usuario: this.state.email,
      password: this.state.password,
    };

    Api.login(body).then(
      (result) => {
        if (result.jwt === undefined) {
          this.props.onLoginComponentCallback(null);
        } else {
          Auth.saveJWT(result.jwt);
          Auth.saveRol(result.rol);
          Auth.savetUser(result.usuario);
          Auth.saveLocalizaciones(result.localizacion);
          Auth.saveHabilitations(result.habilitaciones);
          Auth.saveOrganizaciones(result.organizaciones);
          Auth.saveValidacionU(result.validacion);
          this.props.onLoginComponentCallback(result.jwt);
        }
      },
      (error) => {
        this.props.onLoginComponentCallback(null);
      }
    );
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.loginConsume();
    }
  };


  render() {
    return (
      <form >
        <div className="form-block__input-wrapper">
          <div className="form-group form-group--login">
            <Input
              id="email"
              type="text"
              label="Usuario"
              handleChange={this.handleChange}
            />
            <Input
              id="password"
              type="password"
              label="Contraseña"
              handleChange={this.handleChange}
            />
          </div>
        </div>
        {/* <Button onClick={this.loginConsume} className="form__custom-button">
          Iniciar sesión
        </Button> */}
        <button onClick={this.loginConsume} className="button button--primary full-width" type="submit">
          Ingresar
        </button>
      </form>
    );
  }

}

export default LoginComponent;
