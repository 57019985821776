import React, { useState, useEffect } from "react";
import Line from "../../../components/Dashboard/Charts/LineFavorita";
import {
  Main,
  ContenedorTitulo,
  Titulo,
  ContenedorFechas,
  ContenedorFiltros,
  ContenedorF,
} from "../ElementosStyles";
import Mapa from "../../../views/Ubicacion Sensores/ImageWithPoints ";
import API from "../../../services/api-service";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/styles";
import "./tablaStyles.css";



const Index = () => {
  const query = new URLSearchParams(useLocation().search);
  const idProceso = query.get("deviceID");
  const [data, setData] = useState({});
  const history = useHistory();
  const array = data.ejey

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.obtenerProceso(idProceso);
        setData(response);
      } catch (error) {
        console.log(error);
      }
    };

    if (idProceso) {
      fetchData();
    }
  }, [idProceso]);

  const atras = () => {
    history.push(`/admin/procesos`);
  };

  const obtenerExcel = async () => {
    let documento = {
      _id: idProceso,
    };
    await API.crearExcel(documento);
  };

  const mkt = () => {
    let n = array.length
    let temp = 0;
    for (let i = 0; i < n; i++) {
      let e = Math.exp(-10000 / (array[i] + 273.15));
      temp += e;
    }
    let result = (-10000 / Math.log(temp / n)) - 273.15;

    return result.toFixed(2)
  }

  return (
    <Main>
      <ContenedorFechas>
        <Button onClick={(e) => atras()}>Atras</Button>
      </ContenedorFechas>
      <ContenedorTitulo>Detalle proceso</ContenedorTitulo>

      {Object.keys(data).length > 0 && (
        <>
          <div className="table-container">
            <Table>
              <TableRow className="row-style-par">
                <TableCell variant="head">Producto</TableCell>
                <TableCell>{data.producto}</TableCell>
              </TableRow>
              <TableRow className="row-style-impar">
                <TableCell variant="head">ID Producto</TableCell>
                <TableCell>{data.idProducto}</TableCell>
              </TableRow>
              <TableRow className="row-style-par">
                <TableCell variant="head">Fecha Inicial</TableCell>
                <TableCell>
                  {data.fechaInicial
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("/")}{" "}
                  {data.fechaInicial.split("T")[1].split(":")[0]}:
                  {data.fechaInicial.split("T")[1].split(":")[1]}
                </TableCell>
              </TableRow>
              {"fechaFinal" in data ? (
                <TableRow className="row-style">
                  <TableCell variant="head">Fecha Final</TableCell>
                  <TableCell>
                    {data.status === "En proceso" ? (
                      ""
                    ) : (
                      <>
                        {data.fechaFinal
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")}{" "}
                        {data.fechaFinal.split("T")[1].split(":")[0]}:
                        {data.fechaFinal.split("T")[1].split(":")[1]}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ) : null}

              <TableRow className="row-style-impar">
                <TableCell variant="head">Estado</TableCell>
                <TableCell>{data.status}</TableCell>
              </TableRow>
              <TableRow className="row-style-par">
                <TableCell variant="head">Lugar de inicio</TableCell>
                <TableCell>{data.lugarInicio}</TableCell>
              </TableRow>
              <TableRow className="row-style-impar">
                <TableCell variant="head">Última actualización</TableCell>
                <TableCell>
                  {data.ultimaActualizacion
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("/")}{" "}
                  {data.ultimaActualizacion.split("T")[1].split(":")[0]}:
                  {data.ultimaActualizacion.split("T")[1].split(":")[1]}
                </TableCell>
              </TableRow>
              <TableRow className="row-style-par">
                <TableCell variant="head">Device ID</TableCell>
                <TableCell>{data.deviceID}</TableCell>
              </TableRow>
              {data.status === "Finalizado" ? (
                <TableRow className="row-style-impar">
                  <TableCell variant="head"><strong>MKT</strong></TableCell>
                  <TableCell><strong>{mkt()}</strong></TableCell>
                </TableRow>
              ) : null}
              {"puntoMaximo" in data ? (
                <TableRow className="row-style-par">
                  <TableCell variant="head"><strong>Temperatura máxima</strong></TableCell>
                  <TableCell><strong>{data.puntoMaximo}</strong></TableCell>
                </TableRow>
              ) :
                null
              }
              {"puntoMedio" in data ? (
                <TableRow className="row-style-impar">
                  <TableCell variant="head"><strong>Temperatura media</strong></TableCell>
                  <TableCell><strong>{data.puntoMinimo}</strong></TableCell>
                </TableRow>
              ) :
                null
              }
              {"puntoMinimo" in data ? (
                <TableRow className="row-style-par">
                  <TableCell variant="head"><strong>Temperatura mínima</strong></TableCell>
                  <TableCell><strong>{data.puntoMedio}</strong></TableCell>
                </TableRow>
              ) :
                null
              }
            </Table>
          </div>

          <Button onClick={obtenerExcel}>Descargar Excel</Button>
          <Line array={data} />
        </>
      )}
    </Main>
  );
};

export default Index;
