import React from "react";
import { Line } from "react-chartjs-2";
import { Resizable } from 'react-resizable';
import 'chartjs-adapter-moment';
import { Contenedor, Main } from '../LineChart/ElementsDisplay';

const LineTest = ({ info }) => {
    let options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    tooltipFormat: 'll HH:mm'
                }
            }
        },
        maintainAspectRatio: false
    };

    const adjustedEjex = info.map((fecha) => {
        const date = new Date(fecha.fechaCompleta);
        date.setHours(date.getHours() + 5);
        return date;
    });

    const valorHumedad = info.map((valor) => {
        if(valor.energy === true){
            return "Sin energía"
        }else{
            return "Con energía"
        }
    });

    const data = {
        labels: adjustedEjex,
        datasets: [
            {
                label: "Energía",
                data: info.map((dato) => (dato.energy ? 1 : 0)),
                fill: true,
                pointRadius: 1.8,
                backgroundColor: "rgba(255, 208, 91 ,0.2)",
                borderColor: "rgba(255, 208, 91 ,1)"
            }
        ]
    };

    options.plugins = {
        tooltip: {
            callbacks: {
                label: (context) => {
                    const index = context.dataIndex;
                    const humedad = valorHumedad[index];
                    return `Energía: ${humedad}`;
                }
            }
        }
    };

    return (
        <>
            {info.length > 0 ? <>
                <Main>
                    <Contenedor>
                        <Resizable>
                            <Line data={data} options={options} />
                        </Resizable>
                    </Contenedor>
                </Main>
            </> : <p>No hay Datos</p>}
        </>
    )

};

export default LineTest;
