function saveJWT(token) {
  localStorage.setItem("token", token);
}

function getJWT() {
  let token = localStorage.getItem("token");

  if (token === "undefined" || token === "null") {
    token = null;
  }
  return token;
}

function saveRol(variable) {
  localStorage.setItem("rol", JSON.stringify(variable));
}
function saveLocalizaciones(variable) {
  localStorage.setItem("localizacion", JSON.stringify(variable));
}

function saveAlert(variable) {
  localStorage.setItem("alerta", JSON.stringify(variable));
}

function getAlert() {
  let variable = localStorage.getItem("alerta");

  if (variable === "undefined" || variable === "null") {
    variable = null;
    return variable;
  }
  return JSON.parse(variable);
}

function getRol() {
  let variable = localStorage.getItem("rol");

  if (variable === "undefined" || variable === "null") {
    variable = null;
    return variable;
  }
  return JSON.parse(variable);
}

function saveHabilitations(habilitaciones) {
  localStorage.setItem("habilitaciones", JSON.stringify(habilitaciones));
}

function getHabilitations() {
  let habilitaciones = localStorage.getItem("habilitaciones");

  if (habilitaciones === undefined || habilitaciones === "null") {
    habilitaciones = null;
  }
  return habilitaciones;
}
function getLocalizaciones() {
  let habilitaciones = localStorage.getItem("localizacion");

  if (habilitaciones === undefined || habilitaciones === "null") {
    habilitaciones = null;
  }
  return habilitaciones;
}
function getHabilitations2() {
  let habilitaciones = localStorage.getItem("habilitaciones");

  if (habilitaciones === undefined || habilitaciones === "null") {
    habilitaciones = null;
  }
  return JSON.parse(habilitaciones);
}

function saveOrganizaciones(aux) {
  localStorage.setItem("organizaciones", JSON.stringify(aux));
}
function saveValidacionU(aux) {
  localStorage.setItem("validacionU", JSON.stringify(aux));
}
function saveVariables(aux) {
  localStorage.setItem("variables", JSON.stringify(aux));
}
function getVariables() {
  let aux = localStorage.getItem("variables");
  if (aux === undefined || aux === "null") {
    aux = null;
  }
  return JSON.parse(aux);
}

function getOrganizaciones() {
  let aux = localStorage.getItem("organizaciones");

  if (aux === undefined || aux === "null") {
    aux = null;
  }
  return JSON.parse(aux);
}

function getValidacionU() {
  let aux = localStorage.getItem("validacionU");

  if (aux === undefined || aux === "null") {
    aux = null;
  }
  return JSON.parse(aux);
}

function saveVariablesSinteticas(deviceID) {
  localStorage.setItem("deviceId", JSON.stringify(deviceID));
}

function getVariablesSinteticas() {
  let deviceID = localStorage.getItem("deviceId");

  if (deviceID === "undefined" || deviceID === "null") {
    deviceID = null;
    return deviceID;
  }
  return JSON.parse(deviceID);
}


function setPaginaProductos(configuracion) {
  localStorage.setItem("paginaP", JSON.stringify(configuracion));
}

function getPaginaProdcutos() {
  let deviceID = localStorage.getItem("paginaP");

  if (deviceID === "undefined" || deviceID === "null") {
    deviceID = null;
    return deviceID;
  }
  return JSON.parse(deviceID);
}

function setPaginaProcesos(configuracion) {
  localStorage.setItem("paginaPr", JSON.stringify(configuracion));
}

function getPaginaProcesos() {
  let deviceID = localStorage.getItem("paginaPr");

  if (deviceID === "undefined" || deviceID === "null") {
    deviceID = null;
    return deviceID;
  }
  return JSON.parse(deviceID);
}

function setCurrentProcesos(configuracion) {
  localStorage.setItem("CurrentPr", JSON.stringify(configuracion));
}

function getPCurrentProcesos() {
  let deviceID = localStorage.getItem("CurrentPr");

  if (deviceID === "undefined" || deviceID === "null") {
    deviceID = null;
    return deviceID;
  }
  return JSON.parse(deviceID);
}


function setPCurrentProcesos(configuracion) {
  localStorage.setItem("CurrentP", JSON.stringify(configuracion));
}

function getCurrentProcesos() {
  let deviceID = localStorage.getItem("CurrentP");

  if (deviceID === "undefined" || deviceID === "null") {
    deviceID = null;
    return deviceID;
  }
  return JSON.parse(deviceID);
}

function savetUser(user) {
  localStorage.setItem("usuario", JSON.stringify(user));
}

function getUser() {
  let variable = localStorage.getItem("usuario");

  if (variable === "undefined" || variable === "null") {
    variable = null;
    return variable;
  }
  return JSON.parse(variable);
}

module.exports = {
  saveJWT,
  getJWT,
  saveRol,
  getRol,
  saveHabilitations,
  getHabilitations,
  saveOrganizaciones,
  getOrganizaciones,
  getHabilitations2,
  saveVariables,
  getVariables,
  saveAlert,
  getAlert,
  saveLocalizaciones,
  getLocalizaciones,
  saveValidacionU,
  getValidacionU,
  saveVariablesSinteticas,
  getVariablesSinteticas,
  setPaginaProductos,
  getPaginaProdcutos,
  setPaginaProcesos,
  getPaginaProcesos,
  setCurrentProcesos,
  getPCurrentProcesos,
  setPCurrentProcesos,
  getCurrentProcesos,
  savetUser,
  getUser
};
