import React, { useEffect, useState, useMemo } from "react";
import Api from "../../../services/api-service.js";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Buttonn from "../../../components/Button";
import Select from "../../../components/Entradas/Select";


import {
    ContenedorTitulo,
    Main,
    Titulo,
    ContenedorTabla,
    ContenedorAciones,
    Aciones,
    ContenedorMensajes,
    TituloMensaje,
    Mensajes,
    ContenedorRaccion,
    BotonAccion,
    TituloAccion,
    ContenedorM,
    AcionesD,
    ContendorRepeticion,
    EntradaHoraP,
    SelectHoraP,
    BotonSiguiente,
    Verificacion,
    ContenedorButton,
    ContenedorFecha,
    Fecha,
    Organizacion,
    Th,
    TrC,
    TablaDispo,
    Tr,
    OrganizacionM,
    BotonDevice,
    Organizacion2
} from "../AlertasElements";


let Estado = [
    {
        value: "all",
        label: "Todos",
    },
    {
        value: "Then",
        label: "Then",
    },
    {
        value: "Trigger",
        label: "Trigger",
    }
];


const ViewLog = () => {

    const [data, setData] = useState(null)
    const [estado, setEstado] = useState("all")
    const [tipo, setTipo] = useState("alerta")
    const query = new URLSearchParams(useLocation().search);
    const idAlerta = query.get("id");
    const obteOrganizacion = query.get("organizacion");
    const obteLocalizacion = query.get("localizacion");


    useEffect(() => {
        Api.getAlertOne(idAlerta, obteOrganizacion, estado, tipo).then(
            (response) => {
                if (response.status == "Ok") {
                    setData(response.result)
                } else {

                }
            },
            (error) => { }
        );
    }, [estado]);



    return (
        <>
            <Main>
                <ContenedorTitulo>
                    <Titulo>Datos</Titulo>
                </ContenedorTitulo>
                <Buttonn
                    url={`/admin/events?organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`}
                    message="Atras"
                    style={BotonDevice}
                >
                    Atras
                </Buttonn>
                <Buttonn
                    url={`/admin/editAlert?id=${idAlerta}&organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`}
                    message="Editar"
                    style={BotonDevice}
                >
                    Editar
                </Buttonn>
              
                <Select
                    message="Estado"
                    value={estado}
                    setFunction={setEstado}
                    data={Estado}
                    id="simple-disabled"
                ></Select>
                {/* <Inputt
            message="Nombre del dispositivo"
            value={nombre}
            id="filled-required"
          />
          <Inputt message="MAC ID" value={report} id="filled-required" /> */}
            </Main>
            <Main>
                <ContenedorTabla>
                    <TablaDispo>
                        <Tr>
                            <Th>Nombre Dispositivo</Th>
                            <Th>Mensaje</Th>
                            <Th>Estado</Th>
                            <Th>Tipo</Th>
                            <Th>Fecha</Th>
                            <Th>Hora</Th>
                        </Tr>

                        {data == null ? <p>NO hay datos</p> : (<>  {data.map((data, i) => (
                            <TrC>
                                <td>
                                    <Organizacion>{data.nombre}</Organizacion>
                                </td>
                                <td>
                                    <OrganizacionM>{data.mensaje}</OrganizacionM>
                                </td>
                                <td>
                                    <Organizacion>{data.estado}</Organizacion>
                                </td>
                                <td>
                                    <Organizacion>{data.tipo}</Organizacion>
                                </td>


                                <td>
                                    <ContenedorFecha>
                                        <Fecha> {data.fecha.toString().slice(
                                            0,
                                            data.fecha.toString().indexOf("T")
                                        )}</Fecha>
                                    </ContenedorFecha>
                                </td>
                                <td>
                                    <ContenedorFecha>
                                        <Fecha> {data.fecha.toString().slice(
                                            data.fecha.toString().indexOf("T") + 1,
                                            data.fecha.toString().indexOf("T") + 9
                                        )}</Fecha>
                                    </ContenedorFecha>
                                </td>
                            </TrC>
                        ))}</>)}


                    </TablaDispo>
                </ContenedorTabla>
            </Main>
                



            



            <ContenedorButton>
                <Buttonn
                    url={`/admin/events?organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`}
                    message="Regresar"
                    style={BotonDevice}
                >
                    Regresar
                </Buttonn>
                {/* {Dview == "one" ? (
            <Buttonn
              url={`/admin/wt32?organizacion=${obteOrganizacion}`}
              message="Regresar"
              style={BotonDevice}
            >
              Regresar
            </Buttonn>
          ) : (
            <Buttonn
              url={`/admin/viewAllwt32?organizacion=${obteOrganizacion}`}
              message="Regresar"
              style={BotonDevice}
            >
              Regresar
            </Buttonn>
          )}
  
          {postRol == "usuario" || postRol == "visualizador" ? null : <><BotonDevice
            onClick={() => {
              openModal();
            }}
          >
            Editar
          </BotonDevice>
            <BotonDevice
              onClick={() => {
                Borrar();
              }}
            >
              Borrar
            </BotonDevice></>} */}


            </ContenedorButton>
        </>
    )
}

export default ViewLog