import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import API from "../../services/api-service";
import { useHistory } from "react-router-dom";
import AUTH from "../../services/auth-service";
import { Button, Card, Row, Col, Modal } from "react-bootstrap";
import { FaDoorClosed, FaDoorOpen } from "react-icons/fa";
import { IoIosFlash, IoIosFlashOff } from "react-icons/io";
import { IoBatteryCharging } from "react-icons/io5";
import { FaFaucet } from "react-icons/fa";
import { FaFaucetDrip } from "react-icons/fa6";
import Termometro from "./Widgets/Temperatura/TemperaturaM";
import Humedad from "./Widgets/MedidorHumedad/humedad";
import Presion from "./Widgets/Presion/presion";
import LineMulti from "./Widgets/LineChartMultiple/index";
import { Circles } from "react-loader-spinner";
import {
  Main,
  ContenedorTitulo,
  Titulo,
  ContenedorFechas,
  ContenedorF,
  TituloDispositivo,
  TituloID,
  ContenedorDatos,
  ContenedorI,
  Tarjeta,
  ContedorWigdets2,
} from "./ElementosStyles";
import { ContenedorWidget, Organizacion } from "../Dashboard/DashboardElements";
import Tabla from "./Tabla/index";
import { Contenedor } from "./Widgets/Temperatura/ElementsDisplay";
import Bateria from "../../components/Dashboard/Charts/BateriaH";
import LinePuerta from "./Widgets/LineChartEstados/index";
import LineEnergia from "./Widgets/LineChartEstados/energia";
import LineChart from "./Widgets/LineChart/LineChart";
import LineChart2 from "./Widgets/LineChatTe/LineChart";
import LineChart3 from "./Widgets/LineChartBoo/LineChart";
import LineChart4 from "./Widgets/LineChartPer/LineChart";
import LineTest from "./Widgets/LineChartEstados/desconexiones";
import PuertaAbierta from "./Widgets/Logos/abierto.png";
import PuertaCerrado from "./Widgets/Logos/cerrado.png";
import ModalCentro from "../../components/Modal/Modal Centro";
import Swal from "sweetalert2";
import Temperatura from "../../components/CamposWidgets/EdicionTernometro";
import PdfReport from "../../components/Exportacion/pdf";

const Index = () => {
  const [controladorC, setControladorC] = useState(false);
  const [validador, setvalidador] = useState(false);
  const [deviceID, setDeviceID] = useState();
  const [tipoD, setTipoD] = useState();
  const [ultimosV, setUltimosV] = useState({});
  const [loading, setLoading] = useState(true); // Estado de carga
  const [cargaNo, setCaargaNo] = useState(false); // Estado de carga
  const [intervalo, setIntervalo] = useState(10);
  const [fechaInicial, setFechaInicial] = useState(
    new Date().getTime() - 1000 * 60 * 60 * 24
  );
  const [fechaFinal, setFechaFinal] = useState(new Date().getTime());
  const query = new URLSearchParams(useLocation().search);
  const organizacionn = query.get("organizacion");
  const localizacionn = query.get("localizacion");
  const nombreDevice = query.get("nombre");
  const [data, setData] = useState([]);
  const [dataDesconexiones, setDataDesconexiones] = useState([]);
  const itemsPerPageOptions = [5, 10, 20, 50, 100, 200];
  const [rol, setRol] = useState();
  const [usuario, setUsuario] = useState();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [nuevoNombre, setNuevoNombre] = useState(nombreDevice);

  useEffect(() => {
    (async () => {
      const deviceID = query.get("deviceID");
      const tipo = query.get("tipo");
      setDeviceID(deviceID);
      setTipoD(tipo);

      let documento2 = {
        fechaInicial: fechaInicial,
        fechaFinal: fechaFinal,
        id: deviceID,
      };

      API.verDesconexion(documento2).then(
        (respuesta) => {
          if (respuesta.message === "La sirena nunca se ha desconectado") {
            setDataDesconexiones(respuesta.message);
          } else {
            setDataDesconexiones(respuesta);
          }
        },
        (error) => {}
      );

      let Documento = {
        fechaInicial: fechaInicial,
        fechaFinal: fechaFinal,
        organizacion: organizacionn,
        localizacion: localizacionn,
        tiposDispositivos: tipo,
        deviceID: deviceID,
        intervalo: intervalo,
      };
      setLoading(true);
      API.obtenerHistorico(Documento).then(
        (respuesta) => {
          if (respuesta && respuesta.error === "error") {
            setvalidador(true);
            setCaargaNo(true);
          } else {
            setCaargaNo(false);
            setvalidador(false);
          }
          setData(respuesta.valores || []);
          setUltimosV(respuesta.ultimosV || {});
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setCaargaNo(true);
        }
      );

      setRol(AUTH.getRol());
      setUsuario(AUTH.getUser());
    })();
  }, [controladorC]);

  useEffect(() => {
    const storedFechaInicial = sessionStorage.getItem(`fechaI_${deviceID}`);
    const storedFechaFinal = sessionStorage.getItem(`fechaF_${deviceID}`);
    const storedIntervalo = sessionStorage.getItem(`intervalo_${deviceID}`);
    if (storedFechaInicial) {
      setFechaInicial(new Date(storedFechaInicial));
    } else {
      setFechaInicial(new Date().getTime() - 1000 * 60 * 60 * 24);
    }
    if (storedFechaFinal) {
      setFechaFinal(new Date(storedFechaFinal));
    } else {
      setFechaFinal(new Date().getTime());
    }
    if (storedIntervalo) {
      setIntervalo(parseInt(storedIntervalo));
    } else {
      setIntervalo(10);
    }
  }, [deviceID]);

  const cambiarFechaI = (fecha) => {
    setFechaInicial(fecha);
    sessionStorage.setItem(`fechaI_${deviceID}`, fecha);
  };

  const cambiarFechaF = (fecha) => {
    setFechaFinal(fecha);
    sessionStorage.setItem(`fechaF_${deviceID}`, fecha);
  };

  const cambiarIntervalo = (intervalo) => {
    setIntervalo(intervalo);
    sessionStorage.setItem(`intervalo_${deviceID}`, intervalo);
  };

  const FechaUltima = () => {
    setControladorC(!controladorC);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const verificarNuevoNombre = (e) => {
    const inputValue = e.target.value;
    setNuevoNombre(inputValue);
  };

  const atras = () => {
    history.goBack();
  };

  const mkt = () => {
    const fecha = Date.now();
    const unaHora = fecha - 60 * 60 * 1000;

    const dataReciente = data.filter((item) => {
      const fechaObj = Date.parse(item.fechaCompleta);
      const aux = 5 * 60 * 60 * 1000;
      const newTimestamp = fechaObj + aux;
      return newTimestamp >= unaHora && newTimestamp <= fecha;
    });

    if (dataReciente.length === 0) {
      return "No hay datos en la última hora";
    }

    let n = dataReciente.length;
    let temp = 0;

    for (let i = 0; i < n; i++) {
      let e = Math.exp(-10000 / (dataReciente[i].temperature + 273.15));
      temp += e;
    }

    let result = -10000 / Math.log(temp / n) - 273.15;

    return result.toFixed(2);
  };

  const renderSensorComponent = () => {
    let columnValues;
    var propiedades;
    if (!ultimosV) {
      propiedades = [];
    } else {
      propiedades = Object.keys(ultimosV);
    }

    var fechaFormateada = "";

    if (ultimosV && ultimosV.ultimoDato) {
      const fechaHoraSeparada = ultimosV.ultimoDato.split("T");
      const fecha = fechaHoraSeparada[0];
      const hora = fechaHoraSeparada[1].slice(0, 8);
      const fechaSeparada = fecha.split("-");
      const anio = fechaSeparada[0];
      const mes = fechaSeparada[1];
      const dia = fechaSeparada[2];
      fechaFormateada = `${dia}/${mes}/${anio} ${hora}`;
    }

    switch (tipoD) {
      case "bleSensor_1":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "temperature" },
          { nColumna: "Puerta", nValor: "doorStatus" },
          { nColumna: "Contacto", nValor: "touchingWall" },
          { nColumna: "Bateria", nValor: "battery" },
          { nColumna: "rssi", nValor: "rssi" },
        ];

        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <TituloDispositivo>Últimos Valores</TituloDispositivo>
                      Ultimo dato: {fechaFormateada}
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          Temperatura
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          Bateria
                          <Bateria data={ultimosV.battery} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          {ultimosV.doorStatus ? (
                            <>
                              <ContenedorI src={PuertaCerrado} />
                              <p>Cerrado</p>
                            </>
                          ) : (
                            <>
                              <ContenedorI src={PuertaAbierta} />
                              <p>Abierto</p>
                            </>
                          )}
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
                <Tarjeta>
                  <LineChart2 info={data} />
                  <LineChart4 info={data} />
                </Tarjeta>
              </>
            ) : null}
            <Tarjeta>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
            </Tarjeta>
          </>
        );
      case "bleSensor_2":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "temperature" },
          { nColumna: "Movimiento", nValor: "movementStatus" },
          { nColumna: "Bateria", nValor: "battery" },
          { nColumna: "rssi", nValor: "rssi" },
        ];
        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <p>Ultimo dato: {fechaFormateada}</p>
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Bateria data={ultimosV.battery} />
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
                <Tarjeta>
                  <LineChart2 info={data} />
                  <LineChart3 info={data} />
                </Tarjeta>
              </>
            ) : null}
            <Tarjeta>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
            </Tarjeta>
          </>
        );
      case "bleSensor_3":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "temperature" },
          { nColumna: "Humedad", nValor: "humidity" },
          { nColumna: "rssi", nValor: "rssi" },
          { nColumna: "Bateria", nValor: "battery" },
        ];

        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <p>Ultimo dato: {fechaFormateada}</p>
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Humedad seriesData={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Bateria data={ultimosV.battery} />
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
                <Tarjeta>
                  <LineChart info={data} />
                  <LineChart2 info={data} />
                </Tarjeta>
              </>
            ) : null}
            <Tarjeta>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
            </Tarjeta>
          </>
        );
      case "bleSensor_4":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "temperature" },
          { nColumna: "Humedad", nValor: "humidity" },
          { nColumna: "rssi", nValor: "rssi" },
          { nColumna: "Bateria", nValor: "battery" },
        ];

        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <p>Ultimo dato: {fechaFormateada}</p>
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Humedad seriesData={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Bateria data={ultimosV.battery} />
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
                <Tarjeta>
                  <LineChart info={data} />
                  <LineChart2 info={data} />
                </Tarjeta>
              </>
            ) : null}
            <Tarjeta>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
            </Tarjeta>
          </>
        );
      case "bleSensor_A":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "temperature" },
          { nColumna: "Humedad", nValor: "humidity" },
          { nColumna: "rssi", nValor: "rssi" },
          { nColumna: "Bateria", nValor: "battery" },
        ];

        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <p>Ultimo dato: {fechaFormateada}</p>
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }} >
                          <Humedad seriesData={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }} >
                          <Bateria data={ultimosV.battery} />
                        </div>
                        {usuario === "victor1" && (
                          <div
                            style={{
                              flex: 0.25,
                              backgroundColor: "#bee6e7", // Fondo dorado para llamar la atención
                              padding: "10px",
                              borderRadius: "8px",
                              textAlign: "center",
                              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Sombra para darle relieve
                            }}
                          >
                            <h3 style={{ margin: "0", fontSize: "1.2em" }}>
                              Temperatura MKT
                            </h3>
                            <p style={{ margin: "5px 0", fontSize: "1.2em" }}>
                              {mkt()}°
                            </p>
                            <p style={{ margin: "5px 0", fontSize: "0.9em" }}>
                              Esta temperatura se calculó utilizando los valores
                              registrados una hora antes.
                            </p>
                            <p
                              style={{
                                margin: "5px 0",
                                fontSize: "0.8em",
                                fontStyle: "italic",
                              }}
                            >
                              *La Temperatura Media Cinética (MKT) es una forma de
                              expresar la temperatura general de un producto a lo
                              largo del tiempo.
                            </p>
                          </div>
                        )}
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
                <Tarjeta>
                  <LineChart info={data} />
                  <LineChart2 info={data} />
                </Tarjeta>
              </>
            ) : null}
            <Tarjeta>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
            </Tarjeta>
          </>
        );
      case "s1_favorita_1":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "temperature" },
          { nColumna: "Humedad", nValor: "humidity" },
          { nColumna: "Bateria", nValor: "battery" },
        ];

        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <p>Ultimo dato: {fechaFormateada}</p>
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Humedad seriesData={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Bateria data={ultimosV.battery} />
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
                <Tarjeta>
                  <LineChart info={data} />
                  <LineChart2 info={data} />
                </Tarjeta>
              </>
            ) : null}
            <Tarjeta>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
            </Tarjeta>
          </>
        );
      case "sirenas_01":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "temperature" },
          { nColumna: "Humedad", nValor: "humidity" },
          { nColumna: "Puertas", nValor: "door" },
          { nColumna: "Energia", nValor: "energy" },
        ];

        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <TituloDispositivo>Últimos Valores</TituloDispositivo>
                      Ultimo dato: {fechaFormateada}
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          Temperatura
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          Humedad
                          <Humedad seriesData={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          {ultimosV.door ? (
                            <>
                              Puerta abierta
                              <FaDoorOpen
                                style={{
                                  width: "80%",
                                  height: "100%",
                                  color: "rgba(111, 234, 208)",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              Puerta cerrada
                              <FaDoorClosed
                                style={{
                                  width: "80%",
                                  height: "100%",
                                  color: "rgba(111, 234, 208)",
                                }}
                              />
                            </>
                          )}
                        </div>
                        <div style={{ margin: '1rem' }}>
                          {ultimosV.energy ? (
                            <>
                              Sin energía
                              <IoIosFlashOff
                                style={{
                                  width: "80%",
                                  height: "100%",
                                  color: "rgba(255, 208, 91)",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              Con energía
                              <IoIosFlash
                                style={{
                                  width: "80%",
                                  height: "100%",
                                  color: "rgba(255, 208, 91)",
                                }}
                              />
                            </>
                          )}
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
              </>
            ) : null}
            <Tarjeta>
              <TituloDispositivo>Diagramas</TituloDispositivo>
              <LineChart info={data} />
              <LineChart2 info={data} />
              <LinePuerta info={data} />
              <LineEnergia info={data} />
              {/* <LineTest info={dataDesconexiones} /> */}
              <LineTest info={data} />
            </Tarjeta>
            <Tarjeta>
              <TituloDispositivo>Datos</TituloDispositivo>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
            </Tarjeta>
          </>
        );
      case "epwt32_01":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "temperature" },
          { nColumna: "Humedad", nValor: "humidity" },
          { nColumna: "Puertas", nValor: "door" },
          { nColumna: "Energia", nValor: "energy" },
        ];

        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <p>Ultimo dato: {fechaFormateada}</p>
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Humedad seriesData={ultimosV} />
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
              </>
            ) : null}

            {data.length > 0 ? (
              <>
                <Tarjeta>
                  <LineChart info={data} />
                  <LineChart2 info={data} />
                </Tarjeta>
                <Tarjeta>
                  <Tabla
                    data={data}
                    itemsPerPageOptions={itemsPerPageOptions}
                    columnValues={columnValues}
                  />
                </Tarjeta>
              </>
            ) : null}
          </>
        );
      case "apc_artes_1":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "temperatura" },
          { nColumna: "Humedad", nValor: "humedad" },
        ];
        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <TituloDispositivo>Últimos Valores</TituloDispositivo>
                      Ultimo dato: {fechaFormateada}
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          Temperatura
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          Humedad
                          <Humedad seriesData={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          {ultimosV.liqueo ? (
                            <>
                              <p>Existen goteras</p>
                              <FaFaucetDrip
                                style={{
                                  width: "80%",
                                  height: "100px",
                                  color: "rgba(111, 234, 208)",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <p>No existen goteras</p>
                              <FaFaucet
                                style={{
                                  width: "80%",
                                  height: "100px",
                                  color: "rgba(111, 234, 208)",
                                }}
                              />
                            </>
                          )}
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
              </>
            ) : null}
            <Tarjeta>
              <TituloDispositivo>Diagramas</TituloDispositivo>
              <LineChart info={data} />
              <LineChart2 info={data} />
            </Tarjeta>
            <Tarjeta>
              <TituloDispositivo>Datos</TituloDispositivo>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
              <PdfReport data={data} nombre={nombreDevice} />
            </Tarjeta>
          </>
        );
      case "attom_artes_1":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Humedad", nValor: "humidity" },
          { nColumna: "Presión baja", nValor: "LPpressure" },
          { nColumna: "Presión alta", nValor: "HPpresure" },
          { nColumna: "Temp de succión", nValor: "suctionTemperature" },
          { nColumna: "Temp de suministro", nValor: "supplyTemperature" },
          { nColumna: "Temp de retorno", nValor: "returnTemperature" },
        ];
        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <TituloDispositivo>Últimos Valores</TituloDispositivo>
                      Ultimo dato: {fechaFormateada}
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          Humedad
                          <Humedad seriesData={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          Presión baja
                          <Presion value={ultimosV.LPpressure} unidad="psi" />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          Presión alta
                          <Presion value={ultimosV.HPpresure} unidad="psi" />
                        </div>
                      </ContedorWigdets2>
                      <div>
                        <ContedorWigdets2>
                          <div style={{ margin: '1rem' }}>
                            Temperatura de succión
                            <Termometro
                              info={ultimosV}
                              temperaturaKey="suctionTemperature"
                            />
                          </div>
                          <div style={{ margin: '1rem' }}>
                            Temperatura de suministro
                            <Termometro
                              info={ultimosV}
                              temperaturaKey="supplyTemperature"
                            />
                          </div>
                          <div style={{ margin: '1rem' }}>
                            Temperatura de retorno
                            <Termometro
                              info={ultimosV}
                              temperaturaKey="returnTemperature"
                            />
                          </div>
                        </ContedorWigdets2>
                      </div>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
              </>
            ) : null}
            <Tarjeta>
              <TituloDispositivo>Diagramas</TituloDispositivo>
              <LineChart info={data} />
              <LineMulti info={data} variables={["LPpressure", "HPpresure"]} />
              <LineMulti
                info={data}
                variables={[
                  "suctionTemperature",
                  "supplyTemperature",
                  "returnTemperature",
                ]}
              />
            </Tarjeta>
            <Tarjeta>
              <TituloDispositivo>Datos</TituloDispositivo>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
              <PdfReport data={data} nombre={nombreDevice} />
            </Tarjeta>
          </>
        );
      case "power_artes_1":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          {
            nColumna: "Voltaje Linea 1 neutro",
            nValor: "voltajeLinea1_neutro",
          },
          {
            nColumna: "Voltaje Linea 2 neutro",
            nValor: "voltajeLinea2_neutro",
          },
          {
            nColumna: "Voltaje Linea 3 neutro",
            nValor: "voltajeLinea3_neutro",
          },
        ];
        return (
          <>
            {/* {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <TituloDispositivo>Últimos Valores</TituloDispositivo>
                      Ultimo dato: {fechaFormateada}
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 0.3 }}>
                          Voltaje línea 1 Neutro
                          <Presion value={ultimosV.voltajeLinea1_neutro} unidad='V' />
                        </div>
                        <div style={{ flex: 0.3 }}>
                          Voltaje línea 2 neutro
                          <Presion value={ultimosV.voltajeLinea2_neutro} unidad='V' />
                        </div>
                        <div style={{ flex: 0.3 }}>
                          Voltaje línea 3 neutro
                          <Presion value={ultimosV.voltajeLinea3_neutro} unidad='V' />
                        </div>
                      </div>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
              </>
            ) : null} */}
            <Tarjeta>
              <TituloDispositivo>Gráficos históricos</TituloDispositivo>
              <h5>Voltaje por fases</h5>
              <LineMulti
                info={data}
                variables={[
                  "voltajeLinea1_neutro",
                  "voltajeLinea2_neutro",
                  "voltajeLinea3_neutro",
                ]}
              />
              <h5>Corrientes por fases</h5>
              <LineMulti
                info={data}
                variables={[
                  "corrienteLinea1",
                  "corrienteLinea2",
                  "corrienteLinea3",
                ]}
              />
              <h5>Voltaje entre líneas</h5>
              <LineMulti
                info={data}
                variables={[
                  "voltajeLinea1_Linea2",
                  "voltajeLinea2_Linea3",
                  "voltajeLinea1_Line3",
                ]}
              />
              <h5>Potencia activa</h5>
              <LineMulti
                info={data}
                variables={[
                  "potenciaActivaLinea1",
                  "potenciaActivaLinea2",
                  "potenciaActivaLinea3",
                ]}
              />
              <h5>Potencia reactiva</h5>
              <LineMulti
                info={data}
                variables={[
                  "potenciaReactivaLinea1",
                  "potenciaReactivaLinea2",
                  "potenciaReactivaLinea3",
                ]}
              />
              <h5>Potencia aparente</h5>
              <LineMulti
                info={data}
                variables={[
                  "potenciaAparenteLinea1",
                  "potenciaAparenteLinea2",
                  "potenciaAparenteLinea3",
                ]}
              />
              <h5>Factor de potencia</h5>
              <LineMulti
                info={data}
                variables={[
                  "factorPotenciaLinea1",
                  "factorPotenciaLinea2",
                  "factorPotenciaLinea3",
                ]}
              />
              <h5>cosPhi</h5>
              <LineMulti
                info={data}
                variables={["cosPhiL1", "cosPhiLinea2", "cosPhiLinea3"]}
              />
              <h5>Frecuencia</h5>
              <LineMulti info={data} variables={["frecuencia"]} />
            </Tarjeta>
            <Tarjeta>
              <TituloDispositivo>Datos</TituloDispositivo>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
              <PdfReport data={data} nombre={nombreDevice} />
            </Tarjeta>
          </>
        );
      case "ups_artes_1":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "deviceTemperature" },
          { nColumna: "inputNominalVoltage", nValor: "inputNominalVoltage" },
          { nColumna: "outputLineVoltage", nValor: "outputLineVoltage" },
          { nColumna: "outputLineCurrent", nValor: "outputLineCurrent" },
        ];
        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <TituloDispositivo>Últimos Valores</TituloDispositivo>
                      Ultimo dato: {fechaFormateada}
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          <h5>Input</h5>
                          input nominal voltage
                          <Presion
                            value={ultimosV.inputNominalVoltage}
                            unidad="Vac"
                          />
                        </div>
                      </ContedorWigdets2>
                      <div>
                        <h5>Output</h5>
                        <ContedorWigdets2>
                          <div style={{ margin: '1rem' }}>
                            Output voltage
                            <Presion
                              value={ultimosV.outputLineVoltage}
                              unidad="Vac"
                            />
                          </div>
                          <div style={{ margin: '1rem' }}>
                            Output current
                            <Presion
                              value={ultimosV.outputLineCurrent}
                              unidad="Amp"
                            />
                          </div>
                          <div style={{ margin: '1rem' }}>
                            Output locad
                            <Presion
                              value={ultimosV.outputLinePercentLocad}
                              unidad="%"
                            />
                          </div>
                        </ContedorWigdets2>
                      </div>
                      <div>
                        <h5>Estado UPS</h5>
                        <ContedorWigdets2>
                          <div style={{ margin: '1rem' }}>
                            Edad batería
                            <div>
                              <div>{ultimosV.batteryAge} años</div>
                              <IoBatteryCharging
                                style={{
                                  width: "80%",
                                  height: "100px",
                                  color: "rgba(111, 234, 208)",
                                }}
                              />
                            </div>
                          </div>
                          <div style={{ margin: '1rem' }}>
                            Voltaje de batería
                            <Presion
                              value={ultimosV.batteryVoltage}
                              unidad="Vdc"
                            />
                          </div>
                          <div style={{ margin: '1rem' }}>
                            Carga restante estimada
                            <Bateria data={ultimosV.estimatedChargeRemaning} />
                          </div>
                        </ContedorWigdets2>
                      </div>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
              </>
            ) : null}
            <Tarjeta>
              <TituloDispositivo>Diagramas</TituloDispositivo>
              <h5>Input</h5>
              <LineMulti info={data} variables={["inputNominalVoltage"]} />
              <h5>Output</h5>
              <LineMulti
                info={data}
                variables={["outputLineVoltage", "outputLineCurrent"]}
              />
            </Tarjeta>
            <Tarjeta>
              <TituloDispositivo>Datos</TituloDispositivo>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
              <PdfReport data={data} nombre={nombreDevice} />
            </Tarjeta>
          </>
        );
      case "ble_1":
        columnValues = [
          { nColumna: "Fecha", nValor: "fecha" },
          { nColumna: "Temperatura", nValor: "temperature" },
          { nColumna: "Humedad", nValor: "humidity" },
        ];

        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <p>Ultimo dato: {fechaFormateada}</p>
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Humedad seriesData={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Bateria data={ultimosV.battery} />
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
                <Tarjeta>
                  <LineChart info={data} />
                  <LineChart2 info={data} />
                </Tarjeta>
              </>
            ) : null}
            <Tarjeta>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
            </Tarjeta>
          </>
        );
      case "humitemp_1":
        columnValues = [
          { nColumna: "Fecha", nValor: "fechaCompleta" },
          { nColumna: "Temperatura", nValor: "Tambiente" },
          { nColumna: "Humedad", nValor: "Hambiente" },
        ];

        return (
          <>
            {propiedades.length > 0 ? (
              <>
                {ultimosV && ultimosV.ultimoDato ? (
                  <>
                    <Tarjeta>
                      <p>Ultimo dato: {fechaFormateada}</p>
                      <ContedorWigdets2>
                        <div style={{ margin: '1rem' }}>
                          <Termometro info={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Humedad seriesData={ultimosV} />
                        </div>
                        <div style={{ margin: '1rem' }}>
                          <Bateria data={ultimosV.Vbat} />
                        </div>
                      </ContedorWigdets2>
                    </Tarjeta>
                  </>
                ) : (
                  <p>El dispositivo no ha reportado dentro de 2 horas</p>
                )}
                <Tarjeta>
                  <LineChart info={data} />
                  <LineChart2 info={data} />
                </Tarjeta>
              </>
            ) : null}
            <Tarjeta>
              <Tabla
                data={data}
                itemsPerPageOptions={itemsPerPageOptions}
                columnValues={columnValues}
              />
            </Tarjeta>
          </>
        );
      default:
        return null;
    }
  };

  function actualizarNombre() {
    let documento = {
      organizacion: organizacionn,
      localizacion: localizacionn,
      nombre: nuevoNombre,
      id: ultimosV.deviceID,
      tipo: "sensores",
    };

    API.actualizarNombreDispositivo(documento).then((respuesta) => {
      if (respuesta.success == true) {
        // Swal.fire({ title: "Rango creado/actualizado", icon: "success", confirmButtonColor: "#1976d2", })
        Swal.fire(`${respuesta.message}`, "", "success");
        closeModal();
      } else {
      }
    });
  }

  return (
    <>
      <ModalCentro isOpen={showModal} onClose={closeModal}>
        <h4>Actualización nombre </h4>
        <Button
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={closeModal}
        >
          X
        </Button>
        <ContenedorFechas>
          <TextField
            label="Nombre"
            value={nuevoNombre}
            onChange={verificarNuevoNombre}
          />
        </ContenedorFechas>
        <ContenedorFechas>
          <Button className="buttonGeneral" onClick={actualizarNombre}>
            Actualizar
          </Button>
        </ContenedorFechas>
      </ModalCentro>
      <Main>
        <ContenedorFechas>
          <Button onClick={(e) => atras()}>Atras</Button>
        </ContenedorFechas>
        <ContenedorTitulo>Historicos</ContenedorTitulo>

        <ContenedorFechas>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Fecha Inicial"
              value={fechaInicial}
              onChange={cambiarFechaI}
            />
          </LocalizationProvider>
        </ContenedorFechas>
        <ContenedorFechas>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Fecha Final"
              value={fechaFinal}
              onChange={cambiarFechaF}
            />
          </LocalizationProvider>
        </ContenedorFechas>
        <TextField
          label="Intervalo en minutos"
          value={intervalo}
          onChange={(e) => cambiarIntervalo(e.target.value)}
          sx={{ width: "fit-content" }}
        />
        <ContenedorFechas>
          <Button
            className="buttonGeneral"
            onClick={FechaUltima}
            style={{
              margin: "0px 15px",
            }}
          >
            Buscar en Rango
          </Button>
        </ContenedorFechas>
        <ContenedorDatos>
          {rol == "administrador" && organizacionn != "sto_epconst" ? (
            <Button className="buttonGeneral" onClick={openModal}>
              Actualizar Nombre
            </Button>
          ) : null}

          <TituloDispositivo>{nuevoNombre}</TituloDispositivo>
          <TituloID>{deviceID}</TituloID>
        </ContenedorDatos>
        {cargaNo ? (
          <>
            <p>
              No ha reportado este dispositivo hace 24 horas, revisa el
              historico entre fechas
            </p>
          </>
        ) : null}
        <>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Circles
                height="100"
                width="100"
                color="#5290E9"
                ariaLabel="circles-loading"
                visible={true}
              />
            </div>
          ) : (
            <>{renderSensorComponent()}</>
          )}
        </>
      </Main>
    </>
  );
};

export default Index;
