import React, { useState, useEffect } from "react";
import API from "../../../services/api-service";
import Content from "./Content";
import Sidebar from "./Sidebar";
import { Titulo } from "./Elements";
import { useLocation } from "react-router-dom";

const PlaceHolder = (props) => {

  const query = new URLSearchParams(useLocation().search);
  const organizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const [userData, setUserData] = useState({});
  const [newData, setNewData] = useState([])
  const [dataD, setDataD] = useState([])
  const [idDashboard, setIdDashboard] = useState()


  const onLayoutChange = (layout) => {
    let Data = {
      "organizacion": organizacion,
      "localizacion": localizacion,
      "idDashboard": props.Data._id,
      "newPosicio": layout
    }
    //aqui va el consumo de lasdashbaodo 
    API.actualizarOneDashbaord(Data).then((resp) => {
      setDataD(layout);
    }, (error) => {

    })
  };

  const onResize = (layouts) => {
    setDataD(layouts);
  };

  useEffect(() => {


    if (Object.keys(props.Data).length > 4) {
      // if ((props.Data.posiciones.length > 0) && (props.Data.identificadores.length > 0)) {
      setNewData(props.Data.identificadores);
      setDataD(props.Data.posiciones);
      setIdDashboard(props.Data._id)
    } else {
      setDataD([])
      setNewData([])
    }
    // if (Object.keys(props.Data).length > 4) {
    //   const newNewData = props.Data.widgets.map((inde) => inde.identificador);
    //   setNewData(newNewData);
    //   const newDataD = props.Data.widgets.map((inde) => inde.posicion);
    //   setDataD(newDataD);
    // } else {
    //   setDataD([])
    //   setNewData([])
    // }
  }, [props.Data]);
  /*
  const changeHandler = (index, data) => {
    setUserData({ ...userData, [index]: [...data] });
  };*/
  const save = () => {
    
  };
  return (
    <div style={{ height: "100%" }}>
      <div style={{ display: "flex", flexDirection: "row", minHeight: "95%" }}>
        {/* <Sidebar/> */}
        <div style={{ flex: 1, margin: 10 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3></h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: 300,
              }}
            >
              {/* <button
                onClick={save}
                style={{
                  padding: 20,
                  backgroundColor: "#22a6f9a3",
                  color: "black",
                  outline: "none",
                  border: "2px",
                  cursor: "pointer",
                }}
              >
                Guardar
              </button> */}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {newData.length > 0 ? (
              <div key={1} style={{ marginBottom: 10 }}>                
                <Content
                  idDashboard={idDashboard}
                  newData={newData}
                  dataD={dataD}
                  setNewData={setNewData}
                  setDataD={setDataD} 
                  /*                 
                  change={(data) => {
                    changeHandler(1, data);
                  }}*/
                  onLayoutChange={onLayoutChange}
                  onResize={onResize}
                  funcionEliminar={props.funcionEliminar}
                  ModalEdit={props.ModalEdit}
                  estadoM={props.estadoM}
                  consumir={props.consumir}
                ></Content>

              </div>) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceHolder;
