import React, { useEffect } from 'react';
import "./Login.scss";
import "./Login2.css";
import imegenLogin from "../assets/img/IoT-logo-color.png"
import LoginComponent from '../components/login/login'
import toast, { Toaster, useToasterStore } from 'react-hot-toast';

const TOAST_LIMIT = 1;

const LoginCo = (props) => {
  const { toasts } = useToasterStore();


  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts]);

  const onHandlerLoginComponent = (datos) => {
    props.onLoginCallback(datos);

    if (datos === null) {
      toast.error("Usuario o Contraseña incorrectos.");
    } else {
      toast.success('Acceso Correcto!')
      setTimeout(() => {
        props.history.push("/admin/home");
      }, 1500);
    }
  };

  return (
    <div className={`app app--is-login`}>
      <div class="box">
        <div class="wave -one"></div>
        <div class="wave -two"></div>
        <div class="wave -three"></div>
      </div>
      <div>
        <div className={`form-block-wrapper form-block-wrapper--is-login`}></div>
        <section className={`form-block form-block--is-login`}>
          <header className="form-block__header">
            <><img className="imagenLogin" src={imegenLogin} alt="imagen de inicio de sesión"></img></>
            <h1>
              Inicio de Sesión
            </h1>
          </header>
          <LoginComponent onLoginComponentCallback={onHandlerLoginComponent} />
          <Toaster
            position="top-right"
            reverseOrder={false}
          />
        </section>
      </div>
    </div>
  );
};

export default LoginCo;