import styled from "styled-components";

export const CustomContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const CustomTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
`;

export const CustomSubtitle = styled.h3`
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #666;
`;

export const CustomGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const Label = styled.label`
  flex: 1;
  font-weight: bold;
  color: #555;
`;

export const CustomInput = styled.input`
  flex: 2;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  &:focus {
    border-color: #aaa;
  }
`;

export const CustomTextarea = styled.textarea`
  flex: 2;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  height: 80px;
  resize: vertical;
  &:focus {
    border-color: #aaa;
  }
`;

export const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: auto;
  margin-left: 10px;
`;

export const Button = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`;

export const CustomAddButton = styled(Button)`
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  &:hover {
    background-color: #0056b3;
  }
`;

export const CustomRemoveButton = styled(Button)`
  margin-left: 10px;
  background-color: #dc3545;
  color: white;
  &:hover {
    background-color: #c82333;
  }
`;

export const CustomSaveButton = styled(Button)`
  display: block;
  width: 100%;
  background-color: #28a745;
  color: white;
  margin-top: 20px;
  &:hover {
    background-color: #218838;
  }
`;

export const CustomBackButton = styled(Button)`
  display: block;
  width: 100%;
  background-color: #6c757d;
  color: white;
  margin-top: 10px;
  &:hover {
    background-color: #5a6268;
  }
`;

export const CustomLoading = styled.div`
  font-size: 18px;
  text-align: center;
  color: #666;
`;