import styled from "styled-components";

export const CTituloModal = styled.div`
padding: 15px 15px 15px 15px;
background: #1976d2;
`;

export const TituloModal = styled.h2`
  text-align: center;
  color: white;
`;

export const Opciones = styled.div`
padding: 5px 0px 5px 0px;
  ${'' /* margin: 10px; */}
`;

export const ContenedorFechas = styled.div`
    margin-right: 20px;
    display: inline-block;
`;
