import React, { useState, useEffect } from "react";
import Map from "./mapa";
import { useLocation } from "react-router-dom";
import Api from "../../services/api-service";
import AUTH from "../../services/auth-service"
import { Resizable } from 'react-resizable';
import {
  ContenedorTitulo,
  Main,
  Titulo,
  ParaMapa,
  Tarjeta
} from "./AlcantarillaElements";

const styleMapa = {
  // width: "75%",
  height: "60%",
};

const Index = () => {
  const [devices, setDevices] = useState([]);

  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const tipo = query.get("tipo");


  useEffect(async () => {
    const auth = await AUTH.getOrganizaciones()

    let datas = ""

    auth.map((index) => {
      index.localizacion.map((locali) => {
        if (locali.localiza === localizacion) {
          locali.configuraciones.map((conf) => {
            if (conf.tipo === "mapa") {
              datas = datas + conf.catalogo + ","
            }
          })
        }
      })
    })

    if (datas.endsWith(",")) {
      datas = datas.slice(0, -1);
    }

    if (localizacion === "sto_epconst_loca_2") {
      const bodyReq = { organizacion: obteOrganizacion, localizacion: localizacion };
      Api.viweStatusSiren(bodyReq).then((respuesta) => {
        if (respuesta) {
          setDevices(respuesta);
        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      Api.ubicacionDispo(obteOrganizacion, localizacion, datas).then(
        (respuesta) => {
          if (respuesta.status === true) {
            setDevices(respuesta.data)
          }
        },
        (error) => { 
          console.log(error);
        }
      );
    }
  }, [location.search]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ContenedorTitulo>
          <Titulo>Ubicación de los dispositivos</Titulo>
        </ContenedorTitulo>
        <div style={{ margin: '1rem' }}>
          <Resizable>
            {devices.length ? (
              <ParaMapa>
                <Map devices={devices} styleMapa={styleMapa} />
              </ParaMapa>
            ) : (
              <p>No existe resultados</p>
            )}
          </Resizable>
        </div>
      </div>
    </>
  );
};

export default Index;
