import React from 'react'
import "./styles.css";

const ModalCentro = ({ isOpen, onClose, children }) => {
    const handleBackgroundClick = (event) => {
        if (event.target.classList.contains("modal-backdrop")) {
            onClose();
        }
    };

    return isOpen ? (
        <div className="modales" onClick={handleBackgroundClick}>
            <div className="modal-contentes" onClick={(event) => event.stopPropagation()}>
                <div className="modal-body">{children}</div>
            </div>
        </div>
    ) : null;
}

export default ModalCentro